import React from "react";
import styled from "@emotion/styled";
import { Box, useMediaQuery } from "@mui/material";
import Slider from "./Slider";
import theme from "../../theme";
import { awsUrl } from "../../helpers/config";
import { useOneContext } from "../../helpers/contexts";

interface ProductSliderProps {
  lang: "en" | "ar";
  slides: any;
}

const ProductSlider: React.FC<ProductSliderProps> = ({ lang, slides }) => {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Styles>
      <Slider>
        {slides[lang].map(
          (slide: { mobile: any; desktop: any; alt: string | undefined }) => {
            return (
              <Box className="slider-item">
                <img
                  className={"slider-item-full-image"}
                  src={`${awsUrl}/${mobile ? slide.mobile : slide.desktop}`}
                  alt={slide.alt}
                />
              </Box>
            );
          }
        )}
      </Slider>
    </Styles>
  );
};

const Styles = styled.div`
  &.one {
    min-height: unset;
  }

  direction: ltr;

  * {
    direction: ltr;
  }

  .slider {
    ${({ theme }) => theme.breakpoints.up("md")} {
      min-width: 500px;
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    h2 {
      font-size: 3rem;
    }
  }

  .slider-item {
    width: 100vw;
    min-height: 100vh;
    &.one {
      min-height: unset;
    }
    height: 100%;
    display: grid;
    place-items: center;
    flex: 1;
    flex-direction: row;
    background-color: #00d1d2;

    ${({ theme }) => theme.breakpoints.down("md")} {
      max-width: 100vw;
    }

    .left,
    .right {
      text-align: left;

      ${({ theme }) => theme.breakpoints.down("md")} {
        text-align: center;
      }
    }

    .next-level,
    .you-can-trust {
      color: #34303d;
      text-align: center;
    }

    .right-text {
      max-width: 550px;
      text-align: left;
      color: black;
      margin-top: ${({ theme }) => theme.spacing(2)};
      font-size: 1.2rem;

      &.beautifully-designed {
        color: white;
      }

      ${({ theme }) => theme.breakpoints.down("md")} {
        max-width: 90%;
        margin: auto;
        margin-top: ${({ theme }) => theme.spacing(4)};
      }

      @media all and (max-width: 380px) {
        font-size: 0.9rem !important;
      }
    }

    &-content {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      @media all and (max-width: 380px) {
        justify-content: flex-start;
      }

      ${({ theme }) => theme.breakpoints.down("md")} {
        padding: ${({ theme }) => theme.spacing(2)};

        &-button {
          font-size: 1rem;
        }
      }

      &.other {
        justify-content: space-around;
        @media all and (max-width: 380px) {
          max-height: 600px;
        }
      }
    }

    &-full-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.one {
      position: relative;
      .slider-item-full-image {
        width: 100%;
        height: 100%;
        min-height: 100%;
        object-fit: contain;
        position: relative;
        left: 0;
        top: 0;
      }
    }

    &-half-image {
      width: 100%;
      max-width: 550px;

      ${({ theme }) => theme.breakpoints.up("sm")} {
        &.iqos-circle {
          max-width: 700px;
        }
      }
    }
  }
`;

export default ProductSlider;
