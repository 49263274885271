import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import AgeVerification from '../../components/AgeVerification';
import MalaysiaIluma from '../../components/MalaysiaIluma';

import en from './lang/en.json';
import ms from './lang/ms.json';

const MalaysiaIlumaEntry: React.FC<{lang: 'en' | 'ms'}> = (props) => {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    document.title = 'IQOS Travel';
    const ageVerification = localStorage.getItem('ageVerification');

    if (ageVerification === 'true') {
      setVerified(true);
    }
  }, [verified]);
  
  return (
    <Styles>
      <MalaysiaIluma language={props.lang} translations={props.lang === 'en' ? en : ms} isOnePage />
      <AgeVerification
          location="malaysia"
          language="en"
          verified={verified}
          hasSubmitted={verified}
          url={'/'}
        />
    </Styles>
  );
};

const Styles = styled.div``;

export default MalaysiaIlumaEntry;