import React from "react";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import { awsUrl } from '../../../helpers/config';

const Lead: React.FC = () => {
    const small = useMediaQuery("(max-width: 700px)");
    return (
        <Styles>
            <img src={
                small
                    ? `${awsUrl}/Video-banner-750x473px.jpg`
                    : `${awsUrl}/Video-banner-1080x540.jpg`
            } alt='' className='video-image' />
            <video
                controls
                muted
                autoPlay
                loop 
                className='video'
            >
                <source src={`${awsUrl}/iqos-iluma-prime-oasis-jp.webm`} type="video/webm" />
                <source src={`${awsUrl}/iqos-iluma-prime-oasis-jp.mp4`} type="video/mp4" />
            </video>
        </Styles>
    )
}
const Styles = styled.div`
    position: relative;
    width: 100%;
  
    .video-image {
      width: 100%;
    }
  
    .video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: 80%;
          
        @media screen and (max-width: 700px) {
          width: 80%;
          height: auto;
        }
      
        transform: translateX(-50%) translateY(-50%);
    }
  
    
`;

export default Lead;