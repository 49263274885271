import React from "react";
import styled from "@emotion/styled";
import {Stack, Typography, useMediaQuery} from "@mui/material";
import { awsUrl } from '../../../helpers/config';

const Lead: React.FC<{translations: any}> = ({translations}) => {
    const small = useMediaQuery("(max-width: 700px)");

    return (
        <Styles >
            <img src={
                small
                    ? `${awsUrl}/product-banner-630x1542px.jpg`
                    : `${awsUrl}/Product-banner-1080x540px.jpg`
            } alt='' className='lead-image'/>
            <Stack
                direction={small ? "column" : "row"}
                className="text-stack"
                alignItems={small ? undefined : "center"}
                px={small ? 3 : undefined}
            >
                <div className='padding'/>
                <div className='text'>
                    <Typography variant="h4" sx={{fontSize: small ? '5.8vw' : 'min(4.8vw, 3rem)'}} gutterBottom>
                        予想外の感動は、
                        探究心のその先に。
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: small ? '3.5vw' : 'min(1.5vw, 1rem)'}}>
                        新しいもの、他とは違うもの、大胆なもの
                        を求め、探し続ける。<br/>
                        その進む先には、胸おどる発見がきっと待
                        っている。<br/><br/>
                        IQOSイルマ初の模様デザイン。<br/>
                        見たことのない隠れた宝を手にしたときの
                        ような高揚感を、いまあなたに。<br/><br/>
                        紙巻きたばこから加熱式たばこに切り替え
                        ようとしている方。<br/>
                        新感覚の加熱式たばこを探している方。<br/>
                        いずれの方もIQOSILUMAが提供する煙の
                        出ない、<br/>
                        たばこ本来の満足感のある味わいにきっと
                        感動するはずです。<br/><br/>
                        数量限定 IQOSILUMAオアシスモデル
                    </Typography>
                </div>
            </Stack>
        </Styles>
    )
}
const Styles = styled.div`
    position: relative;
    width: 100%;
  
    .lead-image {
      width: 100%;
    }
  
    .text-stack {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      
      &-small {
        
      }
    }


  .padding {
    // Ratio of 272/639 (gap-left/image)  
    flex: 272;
    
    @media screen and (max-width: 700px) {
      flex: 254;
    }
  }
  
  .text {
    flex: calc(639 - 272);
    
    @media screen and (max-width: 700px) {
        flex: calc(737 - 254);
    }
    position: relative;
  }

  * {
    color: #e2ea9f;
  }
`;

export default Lead;