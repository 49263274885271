import React from "react";
import {Box} from "@mui/material";

const DynamicLink = (props: any) => {
    const { to, ...rest } = props;
    const url = typeof to === 'string' ? to : to.pathname!;
    let isValid = (() => {
        try {
            new URL(url);
            return true
        } catch (e) {
            return false;
        }
    })()

    return (
        <>
            <a href={url} {...rest}/>
        </>
    );
}

export const DynamicLinkWithoutRef = DynamicLink

export default React.forwardRef<unknown, any>((props, ref) => (
    <Box ref={ref}>
        <DynamicLink {...props}/>
    </Box>
));