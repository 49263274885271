import React, { useState } from 'react';
import styled from '@emotion/styled';
import ProductSlider from './ProductSlider';
import IndoorMaps from './IndoorMaps';
import LandingVideo from './LandingVideo';
import Navigation from '../Navigation';
import { OneContext, useCountry } from '../../helpers/contexts';
import FooterNew from './FooterNew';
import ImportantInformation from './ImportantInformation';

const EgyptIlumaPage: React.FC<{
  language: 'en' | 'ar';
  translations: any;
  variant?: 'one' | 'standard';
  isOnePage?: boolean;
}> = ({ language, translations, variant = 'standard', isOnePage }) => {
  const { setCountryOnce } = useCountry();
  const [showImportant, setShowImportant] = useState(false);
  setCountryOnce('Egypt');
  return (
    <OneContext.Provider
      value={{
        isOnePage: isOnePage || false,
      }}>
      {isOnePage !== null ? (
        <Styled className={language === 'ar' ? 'rtl' : 'ltr'}>
          <Navigation
            countryName='Egypt'
            language={language}
            languages={[
              {
                code: 'ar',
                flag: 'ae',
                url: 'ar',
                name: 'العربية',
              },
              {
                code: 'en',
                flag: 'gb',
                url: 'en',
                name: 'English',
              },
            ]}
            translations={translations}
          />
          {showImportant ? (
            <ImportantInformation
              setShowImportant={setShowImportant}
              language={language}
            />
          ) : (
            <>
              <LandingVideo
                translations={translations}
                language={language}></LandingVideo>
              <ProductSlider
                language={language}
                translations={translations}></ProductSlider>
              <IndoorMaps translations={translations}></IndoorMaps>
              <FooterNew
                setShowImportant={setShowImportant}
                translations={translations}
                language={language}
              />
            </>
          )}
        </Styled>
      ) : null}
    </OneContext.Provider>
  );
};

export default EgyptIlumaPage;

const Styled = styled.div`
  a,
  button {
    font-size: 1.25rem;
  }
`;
