import { Box } from "@mui/material";
import styled from "@emotion/styled";

const TextFooter: React.FC<{
  translations: any;
}> = ({ translations }) => {
  // here
  return (
    <Styles>
      <Box className="wrapper">
        <Box>
          {translations?.footer.map((text: any) => (
            <Box className="content-item">{text}</Box>
          ))}
        </Box>
      </Box>
    </Styles>
  );
};

export default TextFooter;

const Styles = styled.div`
  .wrapper {
    background-color: #26242e;
    padding: 50px;
    text-align: center;
  }
  .content-item {
    margin: 20px;
    color: #ffffff;
    opacity: 0.5;
  }
`;
