import React from 'react';
import styled from '@emotion/styled';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { awsUrl } from '../../../../helpers/config';

const BrightLead: React.FC<{}> = () => {
	const width280 = useMediaQuery('(max-width: 280px)');
	const width456 = useMediaQuery('(max-width: 456px)');
	const small = useMediaQuery('(max-width: 700px)');
	const width900 = useMediaQuery('(max-width: 900px)');
	const width1000 = useMediaQuery('(max-width: 1000px)');
	const width1150 = useMediaQuery('(max-width: 1150px)');

	return (
		<Styles>
			<img
				src={
					small
						? `${awsUrl}/bright/preorder-first-lead-background-mobile.png`
						: `${awsUrl}/bright/preorder-first-lead-background-desktop.png`
				}
				alt=""
				className="lead-image"
			/>
			<Stack
				direction={small ? 'column' : 'row'}
				className="text-stack"
				alignItems={small ? undefined : 'center'}
				px={small ? 3 : undefined}
			>
				<div className="padding" />
				<div className="text">
					<Typography
						variant="h4"
						style={{ fontFamily: 'IqosSans' }}
						sx={{
							fontSize:
								(small && '5.8vw') ||
								(width900 && 'min(2.8vw, 1.2rem)') ||
								(width1000 && 'min(2.8vw, 1.5rem)') ||
								(width1150 && 'min(2.8vw, 1.6rem)') ||
								'min(2.8vw, 1.8rem)',
							overflowWrap: 'break-word',
							wordBreak: 'break-word',
						}}
						gutterBottom
					>
						それは、今を輝かせる色
					</Typography>
					<Typography
						variant="body1"
						className="text-main-body"
						style={{ fontFamily: 'IqosSans' }}
						sx={{
							fontSize: small
								? width280
									? '3vw'
									: width456
									? '3.5vw'
									: '3.8vw'
								: 'min(1.3vw, 1rem)',
							overflowWrap: 'break-word',
							wordBreak: 'break-word',
						}}
					>
						色のチカラをIQOSは知っています。 色は、今を祝福し、
						<br />
						喜びの時間をより豊かにしてくれます。 そんな考えから生まれたのが、​
						<br />
						数量限定 IQOS イルマ ブライトモデル。​
						<br />
						その輝きは、手にする喜びをかきたて、 瞬間を際立たせます。 IQOS
						<br />
						イルマを楽しむ時間を特別なものに。 あなたの「今」を、
						<br />
						より鮮やかにします。
					</Typography>
				</div>
			</Stack>
		</Styles>
	);
};
const Styles = styled.div`
	position: relative;
	width: 100%;

	.lead-image {
		width: 100%;
	}

	.text-stack {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&-small {
		}
	}

	.text-main-body {
		overflow-wrap: anywhere;
		line-height: 3;

		@media screen and (max-width: 700px) {
			line-height: 1.75;
		}
	}

	.padding {
		// Ratio of 272/639 (gap-left/image)
		flex: 400;

		@media screen and (max-width: 1200px) {
		}

		@media screen and (max-width: 700px) {
			flex: 300;
		}
	}

	.text {
		flex: calc(639 - 172);

		@media screen and (max-width: 700px) {
			flex: calc(737 - 254);
			padding: 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		position: relative;
		text-align: center;
	}

	* {
		color: #142747;
	}
`;

export default BrightLead;
