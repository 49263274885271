import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import AgeVerification from '../../components/AgeVerification';
import LebanonIluma from '../../components/LebanonIluma';

import en from './lang/en.json';
import ar from './lang/ar.json';

const LebanonIlumaEntry: React.FC<{lang: 'en' | 'ar'}> = (props) => {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    document.title = 'IQOS Travel';
    const ageVerification = localStorage.getItem('ageVerification');

    if (ageVerification === 'true') {
      setVerified(true);
    }
  }, [verified]);
  
  return (
    <Styles>
      <LebanonIluma variant={'one'} language={props.lang} translations={props.lang === 'en' ? en : ar} isOnePage />
      <AgeVerification
          location="lebanon"
          language="en"
          verified={verified}
          hasSubmitted={verified}
          url={'/'}
        />
    </Styles>
  );
};

const Styles = styled.div``;

export default LebanonIlumaEntry;