import {
	Button,
	Grid,
	Link,
	Typography,
	useMediaQuery,
} from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { awsUrl } from '../../helpers/config';

const imageMobile =
	`${awsUrl}/we-jp-offer-mobile.jpg`;
const imageDesktop =
	`${awsUrl}/we-jp-offer-desktop.jpg`;

const Offer: React.FC = () => {
	const large = useMediaQuery('(min-width: 1800px)');
	const desktop = useMediaQuery('(min-width: 1200px)');
	const small = useMediaQuery('(max-width: 700px)');
	const xsmall = useMediaQuery('(max-width: 500px)');
	const xxsmall = useMediaQuery('(max-width: 430px)');

	return (
		<Styled>
			<Grid
				className="international-container"
				container
				direction={desktop ? 'row' : 'column'}
				wrap={desktop ? 'nowrap' : 'wrap'}
			>
				<Grid sx={{ width: '100%' }} className="international-container-image" item>
					<img
						className="image"
						src={desktop ? imageDesktop : imageMobile}
						alt="international"
					/>
				</Grid>
				<Grid
					className="international-container-text"
					item
					container
					direction="column"
					justifyContent="center"
					alignContent="center"
					alignItems={desktop ? 'flex-start' : 'center'}
					sx={{
						margin: large ? '64px' : desktop ? '32px' : '10vw auto',
					}}
				>
					<Typography
						sx={{
							fontSize: large
								? '1.5rem'
								: desktop
								? '1.5rem'
								: small
								? '1rem'
								: xsmall
								? '1rem'
								: '1.5rem',
							overflowWrap: 'break-word',
							wordBreak: 'break-word',
							// whiteSpace: "nowrap"
						}}
						variant="h3"
						className="text-one"
					>
						IQOS 専用たばこスティックを
					</Typography>
					<Typography
						sx={{
							fontSize: large
								? '2rem'
								: desktop
								? '2rem'
								: xxsmall
								? '1.3rem'
								: small
								? '1.5rem'
								: xsmall
								? '1.5rem'
								: '2rem',
							overflowWrap: 'break-word',
							wordBreak: 'break-word',
							// whiteSpace: "nowrap"
						}}
						variant="h3"
						className="text-one"
					>
						3カートン以上購入すると15%off
					</Typography>
					{desktop ? (
						<Typography
							sx={{
								padding: '24px 0',
								fontSize: large
									? '1.5rem'
									: desktop
									? '1.5rem'
									: small
									? '1rem'
									: xsmall
									? '1rem'
									: '1.5rem',
								lineHeight: '1.5',
								maxWidth: desktop ? '100%' : '90%',
								overflowWrap: 'break-word',
								wordBreak: 'break-word',
							}}
							variant="h4"
							className="text-two"
						>
							海外で万が一の故障や自損にも、
							<br />
							24時間日本語対応で、ご指定の場所に
							<br />
							新品をお届けします。
							<br />
							詳しくは下記URLよりご確認ください。
						</Typography>
					) : (
						<Typography
							sx={{
								padding: '24px 0',
								fontSize: large
									? '1.9vw'
									: desktop
									? '1.9vw'
									: small
									? '3.5vw'
									: xsmall
									? '3.5vw'
									: '3.5vw',
								lineHeight: '1.5',
								maxWidth: desktop ? '100%' : '90%',
								overflowWrap: 'break-word',
								wordBreak: 'break-word',
							}}
							variant="h4"
							className="text-two"
						>
							海外で万が一の故障や自損にも、 24時間日本語対応で、ご指定の場所に
							新品をお届けします。 詳しくは下記URLよりご確認ください。
						</Typography>
					)}

					<Link
						sx={{
							width: '300px',
							alignSelf: 'start',
						}}
						target="_blank"
						className="text-link"
						href="https://www.anadf.com/promo/iqos/?utm_source=pm_paidmedia2206&utm_medium=ec2206_jp"
					>
						<Button
							sx={{
								maxWidth: large ? '350px' : desktop ? '350px' : '100%',
								maxHeight: large ? '80px' : desktop ? '60px' : '60px',
								fontSize: large ? '1.5rem' : desktop ? '1.1rem' : '1.1rem',
							}}
							className="text-button gtm-lp-japan-oasis-tag-international-assistance gtmTrackingCTA"
							variant="contained"
						>
							詳細を見る
						</Button>
					</Link>
				</Grid>
			</Grid>
		</Styled>
	);
};

export default Offer;

const Styled = styled.div`
	width: 100%;

	.international-container {
		background-color: #f90213;
		width: 100%;
	}

	.international-container-text {
		font-family: IqosSans, system-ui;
		padding: 0 2rem;

		> * {
			width: 100%;
		}

		h4 {
			max-width: 100%;
		}
	}

	.text-one {
		color: #fff;
		font-weight: 700;
	}

	.text-two {
		color: #34303d;
		font-weight: 700;
	}

	.text-three {
		color: #34303d;
		font-weight: normal;
	}

	.text-link {
		text-decoration: none;
		width: 100%;
	}

	.text-button {
		background: #34303d;
		width: 100%;
		height: 50px;
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
