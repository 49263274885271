import React, { useState } from "react";
import styled from "@emotion/styled";
import ProductSlider from "./ProductSlider";
import Navigation from "../Navigation";
import { OneContext, useCountry } from "../../helpers/contexts";
import ImportantInformation from "../common/ImportantInformation";
import LandingVideo from "../common/LandingVideo";
import TextFooter from "../common/TextFooter";
import IndoorMaps from "./IndoorMaps";
import HealthWarning from "../common/HealthWarning";
import PreOrderBar from "./PreOrderBar";

const UaeMulticatPage: React.FC<{
  language: "en" | "ar";
  translations: any;
  variant?: "one" | "standard";
  isOnePage?: boolean;
}> = ({ language, translations, variant = "standard", isOnePage }) => {
  const { setCountryOnce } = useCountry();
  const [showImportant, setShowImportant] = useState(false);
  setCountryOnce("United Arab Emirates");

  const productSliderSlides = {
    en: [
      {
        mobile: "uae-mobile-slide1-en.jpg",
        desktop: "uae-desktop-slide1-en.jpg",
        alt: "iqos iluma prime one heated tobacco product",
      },
      {
        mobile: "uae-mobile-slide2-en.jpg",
        desktop: "uae-desktop-slide2-en.jpg",
        alt: "iqos iluma prime one heated tobacco product",
      },
      {
        mobile: "uae-mobile-slide3-en.jpg",
        desktop: "uae-desktop-slide3-en.jpg",
        alt: "iqos iluma prime one heated tobacco product",
      },
    ],
    ar: [
      {
        mobile: "uae-mobile-slide1-ar.jpg",
        desktop: "uae-desktop-slide1-ar.jpg",
        alt: "iqos iluma prime one heated tobacco product",
      },
      {
        mobile: "uae-mobile-slide2-ar.jpg",
        desktop: "uae-desktop-slide2-ar.jpg",
        alt: "iqos iluma prime one heated tobacco product",
      },
      {
        mobile: "uae-mobile-slide3-ar.jpg",
        desktop: "uae-desktop-slide3-ar.jpg",
        alt: "iqos iluma prime one heated tobacco product",
      },
    ],
  };
  return (
    <OneContext.Provider
      value={{
        isOnePage: isOnePage || false,
      }}
    >
      {isOnePage !== null ? (
        <Styled className={language === "ar" ? "rtl" : "ltr"}>
          <Navigation
            countryName="UAE"
            language={language}
            languages={[
              {
                code: "ar",
                flag: "ae",
                url: "ar",
                name: "العربية",
              },
              {
                code: "en",
                flag: "gb",
                url: "en",
                name: "English",
              },
            ]}
            // onSidebarOpen={() => setSidebarOpen(true)}
            translations={translations}
          />
          {showImportant ? (
            <ImportantInformation
              setShowImportant={setShowImportant}
              translations={translations}
            />
          ) : (
            <>
              <LandingVideo
                translations={translations}
                language={language}
                awsFileName="uae-multicat"
                //awsFileName="lebanon-iluma"
              />
              {/* <PreOrderBar translations={translations} /> */}
              <ProductSlider lang={language} slides={productSliderSlides} />
              <IndoorMaps translations={translations} />
              <HealthWarning translations={translations} />
              <TextFooter translations={translations} />
            </>
          )}
        </Styled>
      ) : null}
    </OneContext.Provider>
  );
};

export default UaeMulticatPage;

const Styled = styled.div`
  a,
  button {
    font-size: 1.25rem;
  }
`;
