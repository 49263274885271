import React from 'react';
import styled from '@emotion/styled';
import { Button, Card, CardContent, Stack, Typography, useMediaQuery } from '@mui/material';
import theme from '../../theme';
import { awsUrl } from '../../helpers/config';

const WinLanding: React.FC<{ translations: any }> = ({ translations }) => {
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const tablet = useMediaQuery(theme.breakpoints.only('sm'));
	const micro = useMediaQuery('(max-height: 680px)');

	const isArabic = window.location.pathname.includes('ar');

	return (
		<Styles>
			<Stack className={`${isArabic && 'arabic'} attractor`}>
				<img
					alt={'attractor'}
					src={mobile ? `${awsUrl}/WinAttractorMobile.png` : `${awsUrl}/WinAttractorDesktop.png`}
					className={'attractor-img'}
				></img>

				<Stack
					className="attractor-content"
					spacing={micro ? 8 : tablet ? 18 : mobile ? 12 : 0}
				>
					<img
						src={
							mobile
								? `${awsUrl}/discoverIqosandWInTitleSlateInline.svg`
								: `${awsUrl}/discoverIqosandWinTitleSlate.svg`
						}
						alt="title"
						className="attractor-content-title"
					/>
					<Card className={'attractor-card'}>
						<CardContent sx={{ padding: mobile ? 1 : 2 }}>
							<Stack direction={'row'} className="attractor-card-text">
								<span className={'attractor-card-number'}>
									<Typography>1</Typography>
								</span>
								<Typography className="attractor-card-para">
									{translations?.discoverWin?.attractorCardOne}
								</Typography>
							</Stack>
							<Stack direction={'row'} className="attractor-card-text">
								<span className={'attractor-card-number'}>
									<Typography>2</Typography>
								</span>
								<Typography className="attractor-card-para">
									{translations?.discoverWin?.attractorCardTwo}
								</Typography>
							</Stack>
							<Stack direction={'row'} className="attractor-card-text">
								<span className={'attractor-card-number'}>
									<Typography>3</Typography>
								</span>
								<Typography className="attractor-card-para">
									{translations?.discoverWin?.attractorCardThree}
								</Typography>
							</Stack>
						</CardContent>
					</Card>
					<Button
						variant={'contained'}
						className="attractor-button gtm-dubai-win-tag-find-our-pop-up-button gtmTrackingCTA"
						href={'#storeLocator'}
					>
						{translations?.discoverWin?.findPopup}
					</Button>
				</Stack>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	z-index: 10;

	.MuiCardContent-root:last-child {
		padding-bottom: 0;
	}

	.attractor {
		position: relative;
		width: 100vw;
		height: 92vh;
		background-color: ${({ theme }) => theme.palette.secondary.light};
		align-items: flex-start;
		top: 0;
		left: 0;
		z-index: 0;

		&.arabic {
			align-items: flex-end;
		}

		${({ theme }) => theme.breakpoints.down('md')} {
			height: 88vh;
			max-height: 700px;
			align-items: center;
			overflow: hidden;
		}

		&-button {
			margin-top: 2rem;
			background: ${({ theme }) => theme.palette.primary.light};
			height: 50px;
			max-width: 325px;
			font-weight: normal;
			align-self: center;
			font-size: 1.2rem;
			cursor: pointer;
		}

		&-content {
			position: relative;
			top: 0;
			left: 10%;
			padding: 0 2rem;
			height: 100%;
			justify-content: center;
			margin: 0;

			${({ theme }) => theme.breakpoints.down('md')} {
				left: 0;
			}

			&-title {
				margin-bottom: 2rem;
			}
		}

		&-img {
			position: absolute;
			width: 100vw;
			height: 92vh;
			pointer-events: none;
			object-fit: cover;
			object-position: top;

			${({ theme }) => theme.breakpoints.down('md')} {
				max-height: 740px;
				//transform: translateY(0);
				@media all and (max-width: 380px) {
					margin-top: -50px;
				}
			}

			${({ theme }) => theme.breakpoints.only('sm')} {
				margin-top: -20vw;
			}
		}

		&-card {
			max-width: 350px;
			background-color: ${({ theme }) => theme.palette.primary.light};

			color: white;
			border-radius: 20px;

			${({ theme }) => theme.breakpoints.down('md')} {
				top: 40%;
				left: unset;
				align-self: center;
			}

			&-text {
				align-items: center;
				margin: 1.5rem 0;
				padding-bottom: 0 !important;

				p {
					line-height: 1.35;
					font-size: 1.2rem;
					@media all and (max-height: 680px) {
						font-size: 0.9rem;
					}
				}
			}

			&-number {
				text-align: center;
				background-color: ${({ theme }) => theme.palette.secondary.light};
				border-radius: 50%;
				width: 65px;
				height: 65px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				flex-basis: 65px;
				margin: 0rem 1rem;

				p {
					font-size: 2rem !important;
					font-weight: bold !important;
					color: ${({ theme }) => theme.palette.primary.light};
				}
			}
		}
	}
`;

export default WinLanding;
