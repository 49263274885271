import React from "react";
import styled from "@emotion/styled";
import { Stack, useMediaQuery } from "@mui/material";
import { awsUrl } from "../../../helpers/config";

const Header: React.FC<{ langCode: string }> = ({ langCode }) => {
  const small = useMediaQuery("(max-width: 700px)");
  return (
    <Styles>
      <Stack className="header-stack">
        <img
          src={
            small
              ? `${awsUrl}/kr-stardrift-mobile-header-${langCode}.jpg`
              : `${awsUrl}/kr-stardrift-desktop-header-${langCode}.jpg`
          }
          alt=""
        />
      </Stack>
    </Styles>
  );
};

const Styles = styled.div`
  .header-stack {
    width: 100vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export default Header;
