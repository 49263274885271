import { useEffect, useState } from "react";
import AgeVerification from "../../components/AgeVerification";

import en from "./translations/en.json";
import ar from "./translations/ar.json";
import UaeMulticatPage from "../../components/UaeMulticatPage";

const UaeMulticatEntry: React.FC<{ lang: "en" | "ar" }> = (props) => {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    document.title = "IQOS Travel";
    const ageVerification = localStorage.getItem("ageVerification");

    if (ageVerification === "true") {
      setVerified(true);
    }
  }, [verified]);

  return (
    <div>
      <UaeMulticatPage
        language={props.lang}
        translations={props.lang === "en" ? en : ar}
      />
      <AgeVerification
        location="dubai"
        language="en"
        verified={verified}
        hasSubmitted={verified}
        url={"/"}
      />
    </div>
  );
};

export default UaeMulticatEntry;
