import {
	Button,
	Grid,
	Link,
	useMediaQuery,
} from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const Offer: React.FC<{
	backgroundImage: any;
	backgroundColor: any;
	titleSection: any;
	bodySection: any;
	buttonLink: any;
}> = ({ backgroundImage, backgroundColor, titleSection, bodySection, buttonLink }) => {
	const large = useMediaQuery('(min-width: 1800px)');
	const desktop = useMediaQuery('(min-width: 1200px)');

	return (
		<Styled>
			<Grid
				className="international-container"
				style={{ backgroundColor: backgroundColor }}
				container
				direction={desktop ? 'row' : 'column'}
				wrap={desktop ? 'nowrap' : 'wrap'}
			>
				<Grid sx={{ width: '100%' }} className="international-container-image" item>
					<img className="image" src={backgroundImage} alt="international" />
				</Grid>
				<Grid
					className="international-container-text"
					item
					container
					direction="column"
					justifyContent="center"
					alignContent="center"
					alignItems={desktop ? 'flex-start' : 'center'}
					sx={{
						margin: large ? '64px' : desktop ? '32px' : '10vw auto',
					}}
				>
					{titleSection}
					{bodySection}
					<Link
						sx={{
							width: '300px',
							alignSelf: 'start',
						}}
						target="_blank"
						className="text-link"
						href={buttonLink}
					>
						<Button
							style={{ fontFamily: 'IqosSans' }}
							sx={{
								maxWidth: large ? '350px' : desktop ? '350px' : '100%',
								maxHeight: large ? '80px' : desktop ? '60px' : '60px',
								fontSize: large ? '1.5rem' : desktop ? '1.1rem' : '1.1rem',
							}}
							className="text-button gtm-lp-japan-oasis-tag-international-assistance gtmTrackingCTA"
							variant="contained"
						>
							詳細を見る
						</Button>
					</Link>
				</Grid>
			</Grid>
		</Styled>
	);
};

export default Offer;

const Styled = styled.div`
	width: 100%;

	.international-container {
		width: 100%;
	}

	.international-container-text {
		font-family: IqosSans, system-ui;
		padding: 0 2rem;

		> * {
			width: 100%;
		}

		h4 {
			max-width: 100%;
		}
	}

	.text-one {
		color: #fff;
		font-weight: 700;
	}

	.text-two {
		color: #34303d;
		font-weight: 700;
	}

	.text-three {
		color: #34303d;
		font-weight: normal;
	}

	.text-link {
		text-decoration: none;
		width: 100%;
	}

	.text-button {
		background: #34303d;
		width: 100%;
		height: 50px;
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
