import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";

const ImportantInformation: React.FC<{
  setShowImportant: any;
  translations: any;
}> = ({ setShowImportant, translations }) => {
  // here
  return (
    <Styles>
      <Box className="page-wrapper">
        <Box className="content-wrapper">
          <Box className="button-wrapper">
            <Button
              variant={"contained"}
              className="button"
              onClick={() => {
                setShowImportant(false);
              }}
            >
              {translations.importantInformation.backButton}
            </Button>
          </Box>
          <Box className="section-wrapper">
            <Typography className="text-title">
              {translations.importantInformation.title}
            </Typography>
            <Typography className="text-body">
              {translations.importantInformation.description}
            </Typography>
          </Box>
          <Box className="section-wrapper">
            <Typography className="text-title">
              {translations.importantInformation.healthEffectsTitle}
            </Typography>
            <Typography className="text-body">
              {translations.importantInformation.healthEffectsDescription}
            </Typography>
          </Box>
          <Box className="section-wrapper">
            <Typography className="text-title">
              {translations.importantInformation.nicotineTitle}
            </Typography>
            <Typography className="text-body">
              {translations.importantInformation.nicotineDescription}
            </Typography>
          </Box>
          <Box className="section-wrapper">
            <Typography className="text-title">
              {translations.importantInformation.childrenTitle}
            </Typography>
            <Typography className="text-body">
              {translations.importantInformation.childrenDescription}
            </Typography>
          </Box>
          <Box className="section-wrapper">
            <Typography className="text-title">
              {translations.importantInformation.goodPracticesTitle}
            </Typography>
            <Typography className="text-body">
              {translations.importantInformation.goodPracticesDescription}
              <br />
              <br />
              {translations.importantInformation.option1}
              <br />
              {translations.importantInformation.option2}
              <br />
              {translations.importantInformation.option3}
              <br />
              {translations.importantInformation.option4}
              <br />
              {translations.importantInformation.option5}
              <br />
              {translations.importantInformation.option6}
              <br />
              {translations.importantInformation.option7}
              <br />
              {translations.importantInformation.option8}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Styles>
  );
};

export default ImportantInformation;

const Styles = styled.div`
  .page-wrapper {
    height: 100%;
    width: 100%;
    background-color: #35303e;
    min-height: 100vh;
  }
  .button-wrapper {
    /* display: flex;
		justify-content: center;
		align-items: center; */
  }
  .content-wrapper {
    padding: 2rem;
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
  }
  .section-wrapper {
    margin-bottom: 20px;
  }
  .button {
    background: rgba(0, 0, 0, 0);
    height: 40px;
    font-weight: normal;
    align-self: center;
    font-size: 0.8rem;
    cursor: pointer;
    border: white solid 1px;
    box-shadow: none;
  }
  .text-title {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
  }
  .text-body {
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
  }
`;
