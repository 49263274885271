import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Autocomplete,
  Button,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "../../../theme";
import { runFout } from "../../../utils/fout.client";

export interface Operator {
  operator: string;
  coords: any;
}

const PreOrderBar: React.FC<{
  translations: any;
  market?: string;
  firstOperators: any;
  secondOperators: any;
  type: string;
  updateMarkers: any;
  updateOperator: any;
}> = ({
  translations,
  market = "UAE",
  firstOperators,
  secondOperators,
  type,
  updateMarkers,
  updateOperator,
}) => {
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedFirstOperator, setSelectedFirstOperator] =
    useState<Operator | null>(null);
  const [selectedSecondOperator, setSelectedSecondOperator] =
    useState<Operator | null>(null);

  const handleUpdateMarkers = (markers: any) => {
    updateMarkers(markers);
  };

  return (
    <Styles>
      <Stack className="inputs-title" spacing={4}>
        <Typography variant={"body1"} className={"pre-order-text"}>
          {translations?.preOrderBar?.preOrderNow}
        </Typography>
      </Stack>
      <Stack
        className="inputs"
        direction={mobile ? "column" : "row"}
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <Autocomplete
          disablePortal
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                // background: theme.palette.common.white,
                color: theme.palette.common.black,
              }}
              elevation={0}
            >
              {children}
            </Paper>
          )}
          renderOption={(props, option) => {
            return <li {...props}>{option.operator}</li>;
          }}
          // @ts-ignore
          options={firstOperators}
          placeholder={translations?.preOrderBar?.firstSelectorPlaceholder}
          value={selectedFirstOperator}
          getOptionLabel={(option) => {
            return option?.operator;
          }}
          onChange={(e, value: any) => {
            // @ts-ignore
            setSelectedFirstOperator(
              firstOperators?.find((x: any) => x.operator === value.operator) ??
                firstOperators?.[0]?.links?.[0]
            );
            updateOperator(value.operator);
            setSelectedSecondOperator(null);
          }}
          // onChange={}
          renderInput={(params) => (
            <div
              ref={params.InputProps.ref}
              style={{
                backgroundColor: "transparent !important",
              }}
            >
              <input
                placeholder={
                  translations?.preOrderBar?.firstSelectorPlaceholder
                }
                type="text"
                {...params.inputProps}
                className={"location-input"}
              />
            </div>
          )}
        />
        <Autocomplete
          disablePortal
          disabled={selectedFirstOperator === null}
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                // background: theme.palette.common.white,
                color: theme.palette.common.black,
              }}
              elevation={0}
            >
              {children}
            </Paper>
          )}
          renderOption={(props, option) => {
            return <li {...props}>{option.operator}</li>;
          }}
          // @ts-ignore
          options={secondOperators}
          placeholder={translations?.preOrderBar?.secondSelectorPlaceholder}
          value={selectedSecondOperator}
          getOptionLabel={(option) => {
            return option?.operator;
          }}
          onChange={(e, value: any) => {
            // @ts-ignore
            setSelectedSecondOperator(
              secondOperators?.find(
                (x: any) => x.operator === value.operator
              ) ?? secondOperators?.[0]?.links?.[0]
            );
          }}
          // onChange={}
          renderInput={(params) => (
            <div
              ref={params.InputProps.ref}
              style={{
                backgroundColor: "transparent !important",
              }}
            >
              <input
                placeholder={
                  translations?.preOrderBar?.secondSelectorPlaceholder
                }
                type="text"
                {...params.inputProps}
                className={"location-input"}
              />
            </div>
          )}
        />
        <Button
          variant="outlined"
          className={`pre-order-button`}
          sx={{ height: 50, minWidth: "240px" }}
          component={Link}
          fullWidth={mobile}
          target="_blank"
          onClick={() => {
            if (selectedFirstOperator && selectedSecondOperator) {
              handleUpdateMarkers(selectedSecondOperator);
            }
          }}
        >
          {translations?.preOrderBar?.shopNow}
        </Button>
      </Stack>
    </Styles>
  );
};

const Styles = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  //min-height: 10rem;
  background-color: #f7f3f0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;

  .inputs {
    padding-bottom: 40px;
  }

  .inputs-title {
    padding: 40px;
  }

  .location-input {
    border-radius: 30px;
    border: none;
    background-color: #fffefc;
    height: 3rem;
    padding-left: 1rem;
    min-width: 300px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      min-width: 80vw;
      font-size: 16px;
      transform: scale(0.92);
      padding: 1.75rem 2rem;
    }
  }

  .pre-order-button {
    color: white;
    border-color: white;
    padding-left: 1rem;
    background-color: #34303e;
    border-width: 0px !important;
    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding: 20px 24px;
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      transform: scale(0.92);
    }

    &:hover {
      background-color: white;
      color: black;
    }
  }

  .pre-order {
    justify-content: center;
    align-items: center;
    min-width: max-content;
    align-self: flex-start;
    padding-bottom: 1rem;
  }

  .pre-order-icon {
    object-fit: scale-down;
    width: 35px;
    margin-right: 1rem;
  }

  .pre-order-text {
    color: #34303e !important;
    font-size: 26px;
    font-weight: bold;
  }
`;

export default PreOrderBar;
