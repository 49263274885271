export const cdnUrl = 'https://cdn.order.iqostravel.com';
export const awsUrl = `${cdnUrl}/images`;
export const awsSubtitlesUrl = `${cdnUrl}/subtitles`;
export const iqosTravelPreOrder =
	'https://iqostravel.com/LANGUAGE/pre-order?skipDialogs=true&country=south-korea&referrer=korea-iluma';
export const shillaUrl = 'https://www.shilladfs.com/estore/kr/ko/search?text=iqos';
export const lotteUrl =
	'https://kor.lottedfs.com/kr/search?comSearchWord=iqos&comCollection=GOODS&comTcatCD=&comMcatCD=&comScatCD=&comPriceMin=&comPriceMax=&comErpPrdGenVal_YN=&comHsaleIcon_YN=&comSaleIcon_YN=&comCpnIcon_YN=&comSvmnIcon_YN=&comGiftIcon_YN=&comMblSpprcIcon_YN=&comSort=RANK%2FDESC&comListCount=20&txtSearchClickCheck=Y&comSpell_YN=';

export const storeLocator =
	'https://iqostravel.com/MARKET/store-locator?prefill-lat=LATITUDE&prefill-lon=LONGITUDE&skipDialogs=true&country=korea&referrer=oasis&zoom=13&prefill-name=NAME';
