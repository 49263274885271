import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import AgeVerification from "../../components/AgeVerification";
import OasisPage from "../../components/OasisPage";

import en from "./lang/en.json";
import kr from "./lang/kr.json";
import KrStardriftPage from "../../components/KrStardriftPage";

const KrStardriftEntry: React.FC<{ lang: "en" | "kr" }> = ({ lang }) => {
  const [verified, setVerified] = useState(false);
  const [type, setType] = useState<string>("pre-order");

  useEffect(() => {
    document.title = "IQOS Travel";
    const ageVerification = localStorage.getItem("ageVerification");

    if (ageVerification === "true") {
      setVerified(true);
    }
  }, [verified]);

  const [html, setHTML] = useState("");

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://s.yimg.jp/images/listing/tool/cv/ytag.js";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://js.fout.jp/conversion.js";
    script2.async = true;
    document.body.appendChild(script2);

    setHTML(`
      window.yjDataLayer = window.yjDataLayer || [];
      function ytag() { yjDataLayer.push(arguments); }
      ytag({"type":"ycl_cookie"});
      
      window._fout_queue = window._fout_queue || {}; if (window._fout_queue.conv === void 0) window._fout_queue.conv = {};
	  if (_fout_queue.conv.queue === void 0) _fout_queue.conv.queue = [];
    `);
  }, []);

  return (
    <Styles>
      <KrStardriftPage
        language={lang}
        translations={lang === "en" ? en : kr}
        type={type}
      />
      <AgeVerification
        location="oasis"
        language="en"
        verified={verified}
        hasSubmitted={verified}
        url={"/"}
      />
      <script
        async
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Styles>
  );
};

const Styles = styled.div`
  padding: 0;
`;

export default KrStardriftEntry;
