import React from 'react';
import styled from '@emotion/styled';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { awsUrl } from '../../../helpers/config';

const Lead: React.FC<{ translations: any }> = ({ translations }) => {
	const width280 = useMediaQuery('(max-width: 280px)');
	const width456 = useMediaQuery('(max-width: 456px)');
	const small = useMediaQuery('(max-width: 700px)');
	const width900 = useMediaQuery('(max-width: 900px)');
	const width1000 = useMediaQuery('(max-width: 1000px)');
	const width1150 = useMediaQuery('(max-width: 1150px)');

	return (
		<Styles>
			<img
				src={
					small
						? `${awsUrl}/we-jp-lead-mobile.jpg`
						: `${awsUrl}/we-jp-lead-desktop.jpg`
				}
				alt=""
				className="lead-image"
			/>
			<Stack
				direction={small ? 'column' : 'row'}
				className="text-stack"
				alignItems={small ? undefined : 'center'}
				px={small ? 3 : undefined}
			>
				<div className="padding" />
				<div className="text">
					<Typography
						variant="h4"
						sx={{
							fontSize:
								(small && '5.8vw') ||
								(width900 && 'min(2.8vw, 1.2rem)') ||
								(width1000 && 'min(2.8vw, 1.5rem)') ||
								(width1150 && 'min(2.8vw, 1.6rem)') ||
								'min(2.8vw, 1.8rem)',
							overflowWrap: 'break-word',
							wordBreak: 'break-word',
						}}
						gutterBottom
					>
						それぞれの人生、それぞれの情熱、おなじ選択。
					</Typography>
					<Typography
						variant="body1"
						className="text-main-body"
						sx={{
							fontSize: small
								? width280
									? '3vw'
									: width456
									? '3.5vw'
									: '3.8vw'
								: 'min(1.3vw, 1rem)',
							overflowWrap: 'break-word',
							wordBreak: 'break-word',
						}}
					>
						IQOSイルマ <span style={{ color: '#4eb6c5', fontWeight: 'bold' }}>WE</span>
						2023 モデルのカラフルな波模様は、
						<br />
						違いを超えてつながる私たちと、
						<br />
						個性を称賛するIQOSの思いを象徴したもの。
						<br />
						<br />
						世界で1300万人(*)以上ものユーザーが紡ぐIQOSコミュニティは
						<br />
						紙巻たばこを手放し、より良い毎日と未来を選択した人々の集まり。
						<br />
						みんなの個性を紡いだときにより素晴らしいものが生まれます。
						<br />
						<br />
						私たちはIQOSを通してつながっています。
						<br />
						<br />
						<span style={{ color: '#4eb6c5', fontWeight: 'bold' }}>ともに</span>
						、前へと進もう。
						<br />
						Together. Forward. 
					</Typography>
				</div>
			</Stack>
		</Styles>
	);
};
const Styles = styled.div`
	position: relative;
	width: 100%;

	.lead-image {
		width: 100%;
	}

	.text-stack {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&-small {
		}
	}

	.text-main-body {
		overflow-wrap: anywhere;
	}

	.padding {
		// Ratio of 272/639 (gap-left/image)
		flex: 272;

		@media screen and (max-width: 1200px) {
		}

		@media screen and (max-width: 700px) {
			flex: 254;
		}
	}

	.text {
		flex: calc(639 - 172);

		@media screen and (max-width: 700px) {
			flex: calc(737 - 254);
			padding: 30px;
		}

		position: relative;
		text-align: center;
	}

	* {
		color: #142747;
	}
`;

export default Lead;
