import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Footer from './Footer';

const FooterNew: React.FC<{ setShowImportant: any; translations: any; language: any }> = ({
	setShowImportant,
	translations,
	language,
}) => {
	// here
	const isArabic = language === 'ar';
	return (
		<Styles>
			<Box className="content-wrapper">
				{
					translations.footer.lines.map((line: string) => <Typography className="footer-body">
						{line}
					</Typography>)
				}
			</Box>
			<Footer translations={translations} />
			<Box className="links-wrapper">
				<Box>
					{isArabic
						? '© 2022 .Philip Morris Products S.A جميع الحقوق محفوظة. '
						: '© 2022 Phillip Morris Products S.A. All rights reserved.'}
				</Box>
				<Box></Box>
				<Box>
					<a
						href="https://www.pmiprivacy.com/global/en/consumer/"
						style={{ textDecoration: 'none', color: '#6A6C6E' }}
					>
						{isArabic ? 'سياسة الخصوصية' : 'PRIVACY POLICY'}
					</a>
				</Box>
				<Box>
					<a href="https://iqostravel.com/en" style={{ textDecoration: 'none', color: '#6A6C6E' }}>
						{isArabic ? 'تفضيلات ملفات تعريف الارتباط' : 'COOKIE PREFERENCES'}
					</a>
				</Box>
			</Box>
		</Styles>
	);
};

export default FooterNew;

const Styles = styled.div`
	.links-wrapper {
		background-color: #26242e;
		display: flex;
		flex-direction: row;
		min-height: 75px;
		align-items: center;
		justify-content: space-around;
		color: #ffffff;
	}
	.content-wrapper {
		background-color: #ebaf34;
		padding: 50px;
	}
	.section-wrapper {
		margin-bottom: 20px;
	}
	.footer-title {
		font-size: 2rem;
		font-weight: bold;
		color: #ffffff;
	}
	.footer-body {
		font-size: 1.2rem;
		color: #ffffff;
		font-weight: bold;
	}
	.button {
		background: rgba(0, 0, 0, 0);
		height: 40px;
		font-weight: normal;
		align-self: center;
		font-size: 0.8rem;
		cursor: pointer;
		border: white solid 1px;
		box-shadow: none;
	}
`;
