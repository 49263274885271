import React from 'react';
import styled from '@emotion/styled';
import { Typography, useMediaQuery } from '@mui/material';

const Warning: React.FC = () => {
	const small = useMediaQuery('(max-width: 850px)');
	const mobile = useMediaQuery('(max-width: 700px)');
	return (
		<Styles>
			<Typography
				sx={{
					fontSize: small ? '0.8rem' : mobile ? '0.6rem' : '1rem',
					textAlign: 'left',
				}}
			>
				*PMI財務資料/試算（ロシアとウクライナを除く）、IQOSユーザーパネル及び当社市場調査。2022年6月現在。紙巻たばこを完全にやめ、直近7日間
				<br />
				における加熱式たばこ製品の消費量に占めるIQOS専用たばこスティックの割合が7割以上であるIQOSユーザーの数。
			</Typography>
		</Styles>
	);
};

const Styles = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	word-break: inherit;
	color: white;
	padding-left: 27px;
	padding-right: 27px;
	overflow-wrap: anywhere;
`;

export default Warning;
