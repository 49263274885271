import React from 'react';
import styled from '@emotion/styled';
import ProductSlider from '../DubaiPage/ProductSlider';
import IndoorMaps from '../DubaiDI&WPage/IndoorMaps';
import Navigation from '../Navigation';
import Footer from '../DubaiPage/Footer';
import WinLanding from '../DubaiDI&WPage/WinLanding';
import { useCountry } from '../../helpers/contexts';

const DubaiPage: React.FC<{ language: 'en' | 'ar'; translations: any }> = ({
	language,
	translations,
}) => {
	const { setCountryOnce } = useCountry();
	setCountryOnce('United Arab Emirates');
	return (
		<Styled className={language === 'ar' ? 'rtl' : 'ltr'}>
			<Navigation
				countryName="UAE"
				language={language}
				languages={[
					{
						code: 'ar',
						flag: 'ae',
						url: 'ar',
						name: 'العربية',
					},
					{
						code: 'en',
						flag: 'gb',
						url: 'en',
						name: 'English',
					},
				]}
				translations={translations}
			/>
			<WinLanding translations={translations} />
			<ProductSlider translations={translations} language={language} />
			<IndoorMaps translations={translations} />
			<Footer translations={translations} />
		</Styled>
	);
};

export default DubaiPage;

const Styled = styled.div`
	a,
	button {
		font-size: 1.25rem;
	}
`;
