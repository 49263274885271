import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";

import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";

import theme from "./theme";

import DubaiVeevEntry from "./routes/dubai-veev/DubaiVeevEntry";
import DubaiWinEntry from "./routes/dubai-win/DubaiWinEntry";
import EgyptIlumaEntry from "./routes/egypt-iluma/EgyptIlumaEntry";
import IndonesiaIlumaEntry from "./routes/indonesia-iluma/IndonesiaIlumaEntry";
import MalaysiaIlumaEntry from "./routes/malaysia-iluma/MalaysiaIlumaEntry";
import LebanonIlumaEntry from "./routes/lebanon-iluma/LebanonIlumaEntry";

import BrightJapanEntry from "./routes/bright/BrightJapanEntry";
import WeJPEntry from "./routes/we-jp/WeJPEntry";
import OasisJPEntry from "./routes/oasis-jp/OasisJPEntry";

import PageNotFound from "./components/PageNotFound";

import KrStardriftEntry from "./routes/kr-stardrift/KrStardriftEntry";

import UaeMulticatEntry from "./routes/uae-multicat/UaeMulticatEntry";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/404" />} />

            <Route path="/dubai-win/en" element={<DubaiWinEntry lang="en" />} />
            <Route path="/dubai-win/ar" element={<DubaiWinEntry lang="ar" />} />

            <Route
              path="/dubai-veev/en"
              element={<DubaiVeevEntry lang="en" />}
            />
            <Route
              path="/dubai-veev/ar"
              element={<DubaiVeevEntry lang="ar" />}
            />

            <Route
              path="/egypt-iluma/en"
              element={<EgyptIlumaEntry lang="en" />}
            />
            <Route
              path="/egypt-iluma/ar"
              element={<EgyptIlumaEntry lang="ar" />}
            />

            <Route
              path="/kr-stardrift/en"
              element={<KrStardriftEntry lang="en" />}
            />
            <Route
              path="/kr-stardrift/kr"
              element={<KrStardriftEntry lang="kr" />}
            />

            <Route
              path="/lebanon-iluma/en"
              element={<LebanonIlumaEntry lang="en" />}
            />
            <Route
              path="/lebanon-iluma/ar"
              element={<LebanonIlumaEntry lang="ar" />}
            />

            <Route
              path="/indonesia-iluma/en"
              element={<IndonesiaIlumaEntry lang="en" />}
            />
            <Route
              path="/indonesia-iluma/id"
              element={<IndonesiaIlumaEntry lang="id" />}
            />

            <Route
              path="/malaysia-iluma/en"
              element={<MalaysiaIlumaEntry lang="en" />}
            />
            <Route
              path="/malaysia-iluma/ms"
              element={<MalaysiaIlumaEntry lang="ms" />}
            />

            <Route
              path="/uae-multicat/en"
              element={<UaeMulticatEntry lang="en" />}
            />
            <Route
              path="/uae-multicat/ar"
              element={<UaeMulticatEntry lang="ar" />}
            />

            <Route path="/bright/jp" element={<BrightJapanEntry />} />
            <Route path="/we-jp/jp" element={<WeJPEntry />} />
            <Route path="/oasis-jp/jp" element={<OasisJPEntry />} />

            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Router>
      </EmotionThemeProvider>
    </ThemeProvider>
  );
};

export default App;
