import {
  CssBaseline,
  Typography
} from '@mui/material';

const PageNotFound = (): JSX.Element => {
  return (
    <div>
      <CssBaseline />
      <div className="error">
        <Typography variant="h1">
          404: Page Not Found
        </Typography>
      </div>
    </div>
  )
}

export default PageNotFound