import React from "react";
import {
  GoogleMap as ReactGoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import MapIcon from "../images/map-pin.svg";

interface GoogleMapProps {
  coords: {
    lat: number;
    lng: number;
  };
}

export const GoogleMap: React.FC<GoogleMapProps> = ({ coords }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyARt5273gDyPqzMAR1f9CVaPcfB3H0wK4Y",
  });

  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  return isLoaded ? (
    <ReactGoogleMap
      mapContainerStyle={containerStyle}
      center={coords}
      zoom={14}
    >
      <Marker
        icon={{
          url: MapIcon,
          // @ts-ignore
          scaledSize: { width: 30, height: 45 },
        }}
        position={coords}
      />
    </ReactGoogleMap>
  ) : (
    <></>
  );
};
