import React from 'react';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { awsUrl } from '../../../helpers/config';

const Footer: React.FC = () => {
	return (
		<Styles>
			<Stack className={'wrapper'}>
				<img
					src={`${awsUrl}/we-jp-footer-desktop.jpg`}
					alt=""
					className="header-image"
				/>
				<Stack className="text" spacing={2}>
					<Typography
						className="title"
						variant="h4"
						sx={{ fontSize: 'min(4.8vw, 3rem)' }}
					>
						IQOS製品を購入する
					</Typography>
					<Typography variant="body2" sx={{ fontSize: 'min(2.5vw, 2rem)' }}>
						取扱免税店をお探しですか?
						<br />
						事前予約サイトを探す
					</Typography>
				</Stack>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	position: relative;

	.wrapper {
		width: 100vw;
		img {
			height: 100%;
			min-height: 200px;
		}
	}

	.text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		text-align: center;

		color: white;

		.title {
			color: #e2ea9f;
		}
	}
`;

export default Footer;
