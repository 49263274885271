import styled from '@emotion/styled';
import { Box, Button, Container, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { awsUrl } from '../../helpers/config';
import { useCookies } from 'react-cookie';

const differenceInYears = (month: string, year: string): number => {
	const now = new Date();
	const diff = now.getFullYear() - parseInt(year);
	if (now.getMonth() < parseInt(month)) {
		return diff - 1;
	}
	return diff;
};

const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const AgeVerification: React.FC<{
	location?: string;
	language: 'en' | 'ja' | 'ko' | 'id' | 'ms';
	verified: boolean;
	hasSubmitted: boolean;
	url: string;
}> = ({ location, language, verified, hasSubmitted, url }) => {
	const [, setCookie] = useCookies(['ageVerification']);

	// store local storages
	const [year, setYear] = useState<string>();
	const [month, setMonth] = useState<string>();

	useEffect(() => {
		if (verified) return;

		TagManager.dataLayer({
			dataLayerName: 'proxyDataLayer',
			dataLayer: {
				event: 'gatewayLegalAgeDisplay',
			},
		});
	}, [verified]);

	useEffect(() => {
		if (!hasSubmitted) return;

		TagManager.dataLayer({
			dataLayerName: 'proxyDataLayer',
			dataLayer: {
				event: 'gatewayLegalAge',
				selectedLegalAgeBoolean: verified ? 'Yes' : 'No',
			},
		});
	}, [verified, hasSubmitted]);

	if (verified) {
		return <></>;
	}

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!year || !month) return;
		const diff = differenceInYears(month!, year!);
		if (diff >= 20) {
			setCookie('ageVerification', 'true');
			localStorage.setItem('ageVerification', 'true');
		}

		window.location.reload();
	};

	const monthLabel = language === 'en' ? 'MONTH *' : language === 'ko' ? '월*' : '月*';
	const yearLabel = language === 'en' ? 'YEAR *' : language === 'ko' ? '년*' : '年*';
	return (
		<Styled>
			<Box className="verification-container">
				<Container maxWidth="md" className="verification-inner-container">
					<Stack direction="column" spacing={3} alignItems="center">
						{!hasSubmitted ? (
							<>
								<img src={`${awsUrl}/iqos-logo.svg`} className="verification-logo" />
								{language === 'ko' ? (
									<>
										<Typography className="info">
											본 웹사이트에는 담배와 니코틴이 포함된 제품에 대한
											정보가 포함되어 있으며
										</Typography>
										<Typography className="info" sx={{ my: '0 !important' }}>
											만 19세 미만은 전 세계 면세점에서 담배 구입 및 웹사이트
											열람이 제한됩니다.
										</Typography>
									</>
								) : (
									<Typography className="info">
										This website contains information on products to be used
										with tobacco or that contains tobacco and is restricted to
										smokers over the age of 20 in Duty Free locations worldwide.
									</Typography>
								)}

								<Typography variant="h4" component="p" className="question">
									{language === 'en' ? (
										'ARE YOU OVER 20 AND A SMOKER?'
									) : language === 'ko' ? (
										<>
											귀하께서는 만 19세 이상의 성인 흡연자
											<br /> 또는 니코틴 제품 사용자이십니까?'
										</>
									) : (
										'あなたは20歳以上の喫煙者ですか？'
									)}
								</Typography>
								<form onSubmit={onSubmit}>
									<input type="hidden" name="redirect" value={url} />
									<Stack direction="column" spacing={3} alignItems="center">
										<Stack direction="row" spacing={2} justifyContent="center">
											<TextField
												className="selector"
												select
												onChange={(e) => setMonth(e.target.value)}
												value={month}
												placeholder={monthLabel}
												label={monthLabel}
												size="small"
												name="month"
											>
												{new Array(12).fill(0).map((_, i) => {
													// japanese names months the number of moons ('月') so it makes this easier
													const month =
														language === 'en'
															? monthNames[i]
															: language === 'ko'
																? `${i + 1}월`
																: `${i + 1}月`;
													return (
														<MenuItem value={i.toString()}>
															{month}
														</MenuItem>
													);
												})}
											</TextField>
											<TextField
												className="selector"
												select
												onChange={(e) => setYear(e.target.value)}
												value={year}
												placeholder={yearLabel}
												label={yearLabel}
												size="small"
												name="year"
											>
												{new Array(120).fill(0).map((_, i) => {
													const year = new Date().getFullYear() - i;
													return (
														<MenuItem value={year.toString()}>
															{year}
														</MenuItem>
													);
												})}
											</TextField>
										</Stack>
										<Button
											disabled={!year || !month}
											className={`button gtm-landing-page-age-verify-confirm-${location && location
												}`}
											variant="outlined"
											endIcon={
												<img
													style={{ maxHeight: '24px !important' }}
													src={`${awsUrl}/arrow-right.svg`}
													className="arrow-right"
												/>
											}
											type="submit"
										>
											{language === 'en'
												? 'CONFIRM'
												: language === 'ko'
													? '확인'
													: '確認'}
										</Button>
									</Stack>
								</form>
								<Typography className="notice">
									<a href="https://www.pmiprivacy.com/en/consumer">
										{language === 'en'
											? 'For more information please read our Terms and Conditions and Privacy Policy'
											: language === 'ko'
												? '더 자세한 정보는 당사의 이용 약관과 개인정보 보호정책을 확인하시기 바랍니다'
												: '詳しくは利用規約とプライバシーポリシーをご覧ください'}
									</a>
								</Typography>
							</>
						) : (
							<>
								<Typography variant="h4" component="p" className="question">
									{language === 'en'
										? 'SORRY,'
										: language === 'ko'
											? '죄송합니다.'
											: '申し訳ありませんが、'}
								</Typography>
								<Typography variant="h5" component="p">
									{language === 'en'
										? 'THE LAWS OF YOUR COUNTRY DO NOT ALLOW YOU TO ENTER THE SITE.'
										: language === 'ko'
											? '이 사이트는 귀하가 접속하신 국가의 법령에 따라 접근이 허용되지 않습니다.'
											: 'あなたの居住国の法律により、このサイトを閲覧することはできません。'}
								</Typography>
								<Button
									className="button"
									variant="outlined"
									endIcon={<img src={`${awsUrl}/arrow-right.svg`} className="arrow-right" />}
									component={'a'}
									href={'https://google.com/'}
								>
									{language === 'en'
										? 'CONTINUE'
										: language === 'ko'
											? '계속하기'
											: '続ける'}
								</Button>
							</>
						)}
					</Stack>
				</Container>
			</Box>
			<Container className="health-warning">
				<Typography>
					THESE PRODUCTS ARE NOT RISK-FREE AND ARE ADDICTIVE. FOR ADULT USE ONLY.
				</Typography>
			</Container>
		</Styled>
	);
};

const Styled = styled.div`
	.health-warning {
		z-index: 1000;
		position: fixed;
		width: 100vw;
		max-width: unset;
		bottom: 0;
		left: 0;
		height: min-content;
		background-color: white;
		text-align: center;
		padding: 16px;
	}

	.verification-container {
		color: #fff;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: #34303ded;

		backdrop-filter: blur(5px);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		z-index: 999;

		.info {
			font-size: 14px;
		}

		.arrow-right {
			width: 35px;
			max-height: 24px !important;
		}

		.question {
			font-size: 29px;
		}

		.notice {
			a {
				color: white;
			}

			font-size: 10px;
		}

		.verification-logo {
			width: 250px;
			max-width: 54%;
			height: 70px;
		}

		.selector {
			width: 130px;
			/* border: 2px solid #fff; */

			border-radius: 100px;

			* {
				color: #fff;
			}

			fieldset {
				/* border: none !important; */
				border-radius: 100px;
				border-color: #fff;
				outline: none !important;
			}
		}

		.button {
			max-width: 260px;
			background-color: #34303d;
			border-color: #fff;
			letter-spacing: 0;
			border-radius: 100px;
			outline: none !important;
			color: #fff;
		}
	}
`;

export default AgeVerification;
