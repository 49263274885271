import React, { useState } from 'react';
import styled from '@emotion/styled';
import ProductSlider from './ProductSlider';
import IndoorMaps from './IndoorMaps';
import LandingVideo from './LandingVideo';
import Navigation from '../Navigation';
import { OneContext, useCountry } from '../../helpers/contexts';
import FooterNew from './FooterNew';
import ImportantInformation from './ImportantInformation';

const IndonesiaIlumaPage: React.FC<{
	language: 'en' | 'id';
	translations: any;
	isOnePage?: boolean;
}> = ({ language, translations, isOnePage }) => {
	const { setCountryOnce } = useCountry();
	const [showImportant, setShowImportant] = useState(false);
	setCountryOnce('Indonesia');
	return (
		<OneContext.Provider
			value={{
				isOnePage: isOnePage || false,
			}}
		>
			{isOnePage !== null ? (
				<Styled className="ltr">
					<Navigation
						countryName="ID"
						language={language}
						languages={[
							{
								code: 'id',
								flag: 'id',
								url: 'id',
								name: 'Bahasa',
							},
							{
								code: 'en',
								flag: 'gb',
								url: 'en',
								name: 'English',
							},
						]}
						// onSidebarOpen={() => setSidebarOpen(true)}
						translations={translations}
					/>
					{showImportant ? (
						<ImportantInformation
							setShowImportant={setShowImportant}
							language={language}
						/>
					) : (
						<>
							<LandingVideo
								translations={translations}
								language={language}
							></LandingVideo>
							<ProductSlider lang={language} />
							<IndoorMaps translations={translations}></IndoorMaps>
							<FooterNew
								setShowImportant={setShowImportant}
								translations={translations}
								language={language}
							/>
						</>
					)}
				</Styled>
			) : null}
		</OneContext.Provider>
	);
};

export default IndonesiaIlumaPage;

const Styled = styled.div`
	a,
	button {
		font-size: 1.25rem;
	}
`;
