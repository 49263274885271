import React, { useState } from "react";
import {
  GoogleMap as ReactGoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import MapIcon from "../images/map-pin.svg";
import { Container, Stack, useMediaQuery, Box } from "@mui/material";
import styled from "@emotion/styled";

interface GoogleMapProps {
  markers: any;
  defaultFocusCoords: any;
}

export const GoogleMapFullWidth: React.FC<GoogleMapProps> = ({
  markers,
  defaultFocusCoords,
}) => {
  const [popupLocationData, setPopupLocationData] = useState<any | null>(null);
  const [popupWindowCenter, setPopupWindowCenter] = useState({
    lat: 46.204,
    lng: 6.146,
  });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyARt5273gDyPqzMAR1f9CVaPcfB3H0wK4Y",
  });

  const containerStyle = {
    width: "100vw",
    height: "80vh",
    maxHeight: "1000px",
  };

  return isLoaded ? (
    <Styles>
      <ReactGoogleMap
        mapContainerStyle={containerStyle}
        center={
          markers?.coords?.length >= 1 ? markers?.coords[0] : defaultFocusCoords
        }
        zoom={14}
        onClick={() => setPopupLocationData(null)}
      >
        {markers?.coords?.map((item: { lat: any; lng: any; label: string }) => {
          return (
            <Marker
              icon={{
                url: MapIcon,
                // @ts-ignore
                scaledSize: { width: 30, height: 45 },
              }}
              position={{
                lat: item.lat,
                lng: item.lng,
              }}
              onClick={() => {
                setPopupWindowCenter({
                  lat: item.lat,
                  lng: item.lng,
                });
                setPopupLocationData(item);
              }}
            />
          );
        })}
        {popupLocationData != null && (
          <InfoWindow position={popupWindowCenter}>
            <Stack
              sx={{
                padding: 1,
              }}
              alignItems={"center"}
            >
              {popupLocationData.label}
            </Stack>
          </InfoWindow>
        )}
      </ReactGoogleMap>
    </Styles>
  ) : (
    <></>
  );
};

const Styles = styled.div`
  .gm-style-iw {
    border: solid #48bec7 2px;
    border-radius: 0px;
  }
  .gm-ui-hover-effect {
    visibility: hidden;
  }
`;
