import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
	Autocomplete,
	Button,
	Link,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import theme from '../../../theme';
import { runFout } from '../../../utils/fout.client';

export interface Operator {
	operator: string;
	link: string;
}

const PreOrderBar: React.FC<{
	translations: any;
	market?: string;
	operators: any;
	type: string;
}> = ({ translations, market = 'UAE', operators, type }) => {
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const [selectedOperator, setSelectedOperator] = useState<Operator | null>(null);

	return (
		<Styles>
			<Stack
				className="inputs"
				direction={mobile ? 'column' : 'row'}
				spacing={4}
				justifyContent="center"
				alignItems="center"
			>
				<Typography variant={'body1'} className={'pre-order-text'}>
					{type === 'pre-order'
						? translations?.preOrderBar?.preOrderNow
						: translations?.preOrderBar?.airportNow}
				</Typography>
				<Autocomplete
					disablePortal
					PaperComponent={({ children }) => (
						<Paper
							sx={{
								// background: theme.palette.common.white,
								color: theme.palette.common.black,
							}}
							elevation={0}
						>
							{children}
						</Paper>
					)}
					renderOption={(props, option) => {
						return <li {...props}>{option.operator}</li>;
					}}
					// @ts-ignore
					options={operators}
					placeholder={'店舗を選択してください'}
					value={selectedOperator}
					getOptionLabel={(option) => {
						return option?.operator;
					}}
					onChange={(e, value: any) => {
						// @ts-ignore
						setSelectedOperator(
							operators?.find((x: any) => x.operator === value.operator) ??
								operators?.[0]?.links?.[0]
						);
					}}
					// onChange={}
					renderInput={(params) => (
						<div
							ref={params.InputProps.ref}
							style={{
								backgroundColor: 'transparent !important',
							}}
						>
							<input
								placeholder={'店舗を選択してください'}
								type="text"
								{...params.inputProps}
								className={'location-input'}
							/>
						</div>
					)}
				/>
				<Button
					variant="contained"
					className={`pre-order-button ${
						type === 'pre-order'
							? 'gtm-lp-japan-oasis-tag-preorder'
							: 'gtm-lp-japan-oasis-tag-map'
					} gtmTrackingCTA`}
					color="info"
					sx={{ height: 50, minWidth: '240px' }}
					component={Link}
					href={
						selectedOperator?.operator.includes('JAL DUTY FREE')
							? 'https://www.jaldfs.co.jp/ch/shop/pages/iqos_ch.aspx'
							: selectedOperator?.link
					}
					fullWidth={mobile}
					target="_blank"
					onClick={() => {
						if (type === 'pre-order') {
							(window as any).ytag?.({
								type: 'yjad_conversion',
								config: {
									yahoo_ydn_conv_io: 'keehPeF-r94PSSx6xj7QlA..',
									yahoo_ydn_conv_label: 'IHZ0ENCYKJ0NV65JDJ0898403',
									yahoo_ydn_conv_transaction_id: '',
									yahoo_ydn_conv_value: '0',
								},
							});
							(window as any)._fout_queue?.conv?.queue?.push({
								user_id: 43196,
								convtype: 2,
								price: 0,
								dat: '',
							});
							runFout();
						} else {
							(window as any)._fout_queue?.conv?.queue?.push({
								user_id: 43196,
								convtype: 3,
								price: 0,
								dat: '',
							});
							runFout();
						}
					}}
				>
					{translations?.preOrderBar?.shopNow}
				</Button>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	padding: ${({ theme }) => theme.spacing(4)};
	//min-height: 10rem;
	background-color: #34303d;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100vw;

	.location-input {
		border-radius: 30px;
		border: none;
		background-color: #f1f1f1;
		height: 3rem;
		padding-left: 1rem;
		min-width: 300px;

		${({ theme }) => theme.breakpoints.down('md')} {
			min-width: 80vw;
			font-size: 16px;
			transform: scale(0.92);
			padding: 1.75rem 2rem;
		}
	}

	.pre-order-button {
		color: black;
		background-color: #ebaf34;
		border-color: white;
		padding-left: 1rem;
		${({ theme }) => theme.breakpoints.down('lg')} {
			padding: 20px 24px;
		}
		${({ theme }) => theme.breakpoints.down('md')} {
			transform: scale(0.92);
		}

		&:hover {
			background-color: white;
			color: black;
		}
	}

	.pre-order {
		justify-content: center;
		align-items: center;
		min-width: max-content;
		align-self: flex-start;
		padding-bottom: 1rem;
	}

	.pre-order-icon {
		object-fit: scale-down;
		width: 35px;
		margin-right: 1rem;
	}

	.pre-order-text {
		color: white !important;
	}
`;

export default PreOrderBar;
