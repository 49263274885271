import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
	Autocomplete,
	Box,
	Button,
	Grid,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import theme from '../../theme';

import { ChevronRight } from '@mui/icons-material';
import TagManager from 'react-gtm-module';
import { GoogleMap } from '../GoogleMap/GoogleMap';
import { awsUrl } from '../../helpers/config';

interface Map {
	coords: {
		lat: number;
		lng: number;
	};
	title: string;
	index: number;
}

const IndoorMaps: React.FC<{ translations: any }> = ({ translations }) => {
	const maps: Map[] = [
		{
			title: 'Ngurah Rai International Airport Departure Shop (Bali)',
			index: 1,
			coords: {
				lat: -8.7469911,
				lng: 115.1681653,
			},
		},
		{
			title: 'Soekarno-Hatta International Airport Terminal 3 Departure Shop (Jakarta)',
			index: 2,
			coords: {
				lat: -6.1263784,
				lng: 106.6547202,
			},
		},
	];
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const [selectedValue, setSelectedValue] = useState<Map | null>(null);
	const [url, setUrl] = useState<Map | null>(selectedValue);

	return (
		<Styles>
			<Stack
				className={'map-wrapper'}
				direction={mobile ? 'column' : 'row'}
				id={'storeLocator'}
			>
				<Stack className={'map-wrapper-inputs'}>
					<Typography variant={'h4'} className={'map-wrapper-inputs-title'}>
						<img
							alt={'pin'}
							src={`${awsUrl}/pin-icon.png`}
							className={'map-wrapper-inputs-icon'}
						/>
						{translations.storeLocator.findClosest}
					</Typography>

					<Grid
						className="inputs"
						container
						item
						direction={'column'}
						spacing={2}
						wrap="nowrap"
						justifyContent="flex-start"
						alignItems="flex-start"
						mt={2}
					>
						<Grid item>
							<Autocomplete
								disablePortal
								disabled
								PaperComponent={({ children }) => (
									<Paper
										sx={{
											background: theme.palette.common.white,
											color: theme.palette.common.black,
										}}
										elevation={0}
									>
										{children}
									</Paper>
								)}
								renderOption={(props, option) => {
									return <li {...props}>{option}</li>;
								}}
								options={[]}
								placeholder={translations.country}
								value={translations?.preOrderBar?.country}
								readOnly
								getOptionLabel={(option) => {
									return option;
								}}
								renderInput={(params) => (
									<div
										ref={params.InputProps.ref}
										style={{
											backgroundColor: 'transparent !important',
										}}
									>
										<input
											placeholder={translations?.preOrderBar?.country}
											type="text"
											{...params.inputProps}
											className="location-input disabled"
										/>
									</div>
								)}
							/>
						</Grid>

						<Grid item className={'gridItem'}>
							<Autocomplete
								disabled
								disablePortal
								className={'autocomplete'}
								readOnly
								PaperComponent={({ children }) => (
									<Paper
										sx={{
											background: theme.palette.common.white,
											color: theme.palette.common.black,
										}}
										elevation={0}
									>
										{children}
									</Paper>
								)}
								renderOption={(props, option) => {
									return <li {...props}>{option}</li>;
								}}
								// @ts-ignore
								options={[]}
								placeholder={translations?.preOrderBar?.airport}
								value={translations?.preOrderBar?.airport}
								onChange={() => {}}
								getOptionLabel={(option) => {
									return option;
								}}
								renderInput={(params) => (
									<div
										ref={params.InputProps.ref}
										className={'location-input-container'}
									>
										<input
											placeholder={translations?.preOrderBar?.airport}
											type="text"
											{...params.inputProps}
											className="location-input disabled"
										/>
									</div>
								)}
							/>
						</Grid>
						<Grid item>
							<Autocomplete
								disablePortal
								PaperComponent={({ children }) => (
									<Paper
										sx={{
											// background: theme.palette.common.white,
											color: theme.palette.common.black,
										}}
										elevation={0}
									>
										{children}
									</Paper>
								)}
								renderOption={(props, option) => {
									return <li {...props}>{option.title}</li>;
								}}
								// @ts-ignore
								options={maps}
								value={selectedValue}
								getOptionLabel={(option) => {
									return option.title;
								}}
								onChange={(e, value) => {
									// @ts-ignore
									setSelectedValue(
										maps?.find((x) => x.title === value?.title) ?? maps[0]
									);
								}}
								// onChange={}
								renderInput={(params) => (
									<div
										ref={params.InputProps.ref}
										style={{
											backgroundColor: 'transparent !important',
											position: 'relative',
										}}
									>
										<input
											placeholder={'Select your terminal'}
											type="text"
											{...params.inputProps}
											className={`location-input Landing page_Dubai_Iluma_Tag_Show_map_${selectedValue?.index}`}
										/>
										<ChevronRight
											fontSize={'large'}
											className={'chevron'}
											sx={{
												position: 'absolute',
												right: 0,
												top: '50%',
												transform: 'translateY(-50%)',
											}}
										/>
									</div>
								)}
							/>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								className={`location-button gtm-landing-page-dubai-iluma-tag-show-map-${selectedValue?.index} gtmTrackingCTA`}
								color="info"
								sx={{ my: 2, minWidth: '240px' }}
								onClick={(e: any) => {
									setUrl(selectedValue);
									TagManager.dataLayer({
										dataLayerName: 'proxyDataLayer',
										dataLayer: {
											event: 'storeLocatorFindClosestStore',
											airportName: translations?.preOrderBar?.airport,
											airportCountry: translations?.preOrderBar?.country,
											terminalName: selectedValue?.title,
										},
									});
								}}
								href={mobile ? '#map-link' : ''}
							>
								{translations?.storeLocator?.showMap}
							</Button>
						</Grid>
					</Grid>
				</Stack>
				<Box className={'map-wrapper outer-map-box'}>
					<Box className={'map-1 map-box'}>
						{url && <GoogleMap coords={{ lat: url.coords.lat, lng: url.coords.lng }} />}
					</Box>
				</Box>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
    min-height: 60vh;
    background-color: #f3f3f3;
	padding-top: 2rem;

    .outer-map-box {
        ${({ theme }) => theme.breakpoints.down('md')} {
            padding: 1rem 0 !important;
        }

        margin-top: 50px;
	    min-width: 300px;
    }

    .map-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        padding: ${({ theme }) => theme.spacing(4)};
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 2rem;


        &-inputs-icon {
            max-height: 32px;
            margin-right: 1rem;
        }

        &-inputs-title {
            min-height: 50px;
            max-width: 600px;

            ${({ theme }) => theme.breakpoints.down('md')} {
                height: auto;
            }
        }

    }

    .location-input {
        border-radius: 40px;
        border: none;
        background-color: white;
        padding: 14px 24px;
        font-size: 1rem;
        min-width: 280px;
      
        ${({ theme }) => theme.breakpoints.down('md')} {
            min-width: 80vw;
        }

        &.disabled {
            background-color: #c5c5c5;
            color: white !important;

        }
    }

    .location-button {
        padding: 10px 24px;
        font-weight: normal;
        font-size: 1rem;

        ${({ theme }) => theme.breakpoints.down('md')} {
            min-width: 80vw;
        }

        background-color: #34303D;
    }

    .store {
        margin-top: 1rem;

        &-name {
            font-weight: bold;
            font-size: 1.2rem;
        }

        &-terminal {
            padding-left: ${({ theme }) => theme.spacing(6)}
        }
    }

    .store-number {
        background-color: ${({ theme }) => theme.palette.primary.main};
        border-radius: 50%;
        display: inline-block;
        min-width: 34px;
        height: 34px;
        text-align: center;
        margin: 0.5rem;
    }

    &-inputs, &-map {
        flex: 1 0 50%;
        margin: 2rem;

        &-title {
            margin-bottom: ${({ theme }) => theme.spacing(4)};
        }
    }
}

.destination-wrapper {
    position: relative;

    .inputs {
        margin-bottom: 1.5em;
    }

    .location-input {
        display: none !important;
        outline: none;
        border: solid red;
        border-radius: 50px;
        padding: 1rem 2rem;
        min-width: 300px;
        font-size: 1rem;
        font-weight: 600;
        color: #35303e;
        background: transparent;
    }

    .location-select {
        outline: none;
        border: none;
        border-radius: 50px;
        padding: 1rem;
        margin: 1rem;
        min-width: 300px;
        font-size: 1rem;
        font-weight: 600;
        color: #35303e;
    }

    .location-option {
        outline: none;
        border: none;
        border-radius: 50px;
        padding: 1rem;
        min-width: 300px;
        font-size: 1rem;
        font-weight: 600;
        color: #35303e;
    }

    .Mui-disabled {
        color: #35303e;
        border-color: #35303e;
        //background: transparent;
        opacity: 0.8;
    }
`;

export default IndoorMaps;
