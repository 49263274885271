import {
	Typography,
	useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import AgeVerification from '../../components/AgeVerification';
import DynamicPromoPage from '../../components/DynamicPromoPage';
import BrightLead from '../../components/DynamicPromoPage/LeadComponents/BrightLead';

import data from './lang/jp.json';
import { awsUrl } from '../../helpers/config';

const Home: React.FC = () => {
  const [verified, setVerified] = useState(false);
	const [type, setType] = useState<string>('');

  useEffect(() => {
    document.title = 'IQOSイルマ BRIGHT';
    const ageVerification = localStorage.getItem('ageVerification');

    if (ageVerification === 'true') {
      setVerified(true);
    }
  }, [verified]);

	useEffect(() => {
		const url = new URL(window.location.href);
		let _type = url.searchParams.get('type') as string;
		if (!_type) {
			url.searchParams.append('type', 'pre-order');
			window.history.pushState({}, '', url.href);
			_type = 'pre-order';
		}

		setType(_type);
  }, []);

	const [html, setHTML] = useState('');
	const mobile = useMediaQuery('(max-width: 700px)');
	const large = useMediaQuery('(min-width: 1800px)');
	const desktop = useMediaQuery('(min-width: 1200px)');
	const small = useMediaQuery('(max-width: 700px)');
	const xsmall = useMediaQuery('(max-width: 500px)');
	const xxsmall = useMediaQuery('(max-width: 430px)');

	useEffect(() => {
		const script1 = document.createElement('script');
		script1.src = 'https://s.yimg.jp/images/listing/tool/cv/ytag.js';
		script1.async = true;
		document.body.appendChild(script1);

		const script2 = document.createElement('script');
		script2.src = 'https://js.fout.jp/conversion.js';
		script2.async = true;
		document.body.appendChild(script2);

		setHTML(`
      window.yjDataLayer = window.yjDataLayer || [];
      function ytag() { yjDataLayer.push(arguments); }
      ytag({"type":"ycl_cookie"});
      
      window._fout_queue = window._fout_queue || {}; if (window._fout_queue.conv === void 0) window._fout_queue.conv = {};
	  if (_fout_queue.conv.queue === void 0) _fout_queue.conv.queue = [];
    `);
	}, []);

	return (
		<Styles>
				<DynamicPromoPage
					language="ja"
					translations={data}
					type={type}
					headerImage={
						mobile
							? `${awsUrl}/bright/preorder-header-mobile.png`
							: `${awsUrl}/bright/preorder-header-desktop.png`
					}
					mobile={mobile}
					pageColor="white"
					leadComponent={<BrightLead />}
					offerImage={
						mobile
							? `${awsUrl}/bright/preorder-offer-mobile.png`
							: `${awsUrl}/bright/preorder-offer-desktop.png`
					}
					offerColor="#00b3f3"
					offerTitles={
						<>
							<Typography
								style={{ fontFamily: 'IqosSans' }}
								sx={{
									fontSize: large
										? '2.5rem'
										: desktop
										? '2.5rem'
										: xxsmall
										? '1.8rem'
										: small
										? '2rem'
										: xsmall
										? '1.5rem'
										: '2.5rem',
									overflowWrap: 'break-word',
									wordBreak: 'break-word',
								}}
								variant="h3"
								className="text-one"
							>
								海外アシスタンス<br />サービス
							</Typography>
						</>
					}
					offerBodyTextDesktop={
						<Typography
							style={{ fontFamily: 'IqosSans' }}
							sx={{
								padding: '24px 0',
								fontSize: large
									? '1.5rem'
									: desktop
									? '1.5rem'
									: small
									? '1rem'
									: xsmall
									? '1rem'
									: '1.5rem',
								lineHeight: '1.5',
								maxWidth: desktop ? '100%' : '90%',
								overflowWrap: 'break-word',
								wordBreak: 'break-word',
							}}
							variant="h4"
							className="text-two"
						>
							海外で万が一の故障や自損にも、
							<br />
							24時間日本語対応で、ご指定の場所に
							<br />
							新品をお届けします。
							<br />
							詳しくは下記URLよりご確認ください。
						</Typography>
					}
					offerBodyTextMobile={
						<Typography
							style={{ fontFamily: 'IqosSans' }}
							sx={{
								padding: '24px 0',
								fontSize: large
									? '1.9vw'
									: desktop
									? '1.9vw'
									: small
									? '3.5vw'
									: xsmall
									? '3.5vw'
									: '3.5vw',
								lineHeight: '1.5',
								maxWidth: desktop ? '100%' : '90%',
								overflowWrap: 'break-word',
								wordBreak: 'break-word',
							}}
							variant="h4"
							className="text-two"
						>
							海外で万が一の故障や自損にも、
							<br />
							24時間日本語対応で、ご指定の場所に
							<br />
							新品をお届けします。
							<br />
							詳しくは下記URLよりご確認ください。
						</Typography>
					}
					footerImage={`${awsUrl}/bright/preorder-footer-desktop.png`}
					footerTitleColor="white"
					footerTitle="IQOS製品を購入する"
					footerBody={
						<>
							取扱免税店をお探しですか？
							<br />
							事前予約サイトを探す
						</>
					}
					offerButtonLink="https://jp.iqos.com/support/faq/international-assistance"
				/>
				<AgeVerification
					location="oasis"
					language="ja"
					verified={verified}
					hasSubmitted={verified}
					url={'/'}
				/>
			<script
				async
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
		</Styles>
	);
};

const Styles = styled.div`
	padding: 0;
`;

export default Home;
