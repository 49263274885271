import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Warning: React.FC = () => {
	return (
		<Styles>
			<Typography>
				IQOS ILUMAとIQOS ILUMA専用たばこ スティックを従来のIQOS デバイス （IQOS 3
				DUO及びそれ以前のモデル） や それらの専用たばこスティックと一緒に使
				用しないでください。これにより、デバイ スが損傷する可能性があります。詳しくは
				jp.iqos.comへ。
				<br />
				<br />
				*この先はフィリップモリスジャパンが運 営するIQOS公式サイトに移動します
			</Typography>
		</Styles>
	);
};

const Styles = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	word-break: inherit;
`;

export default Warning;
