import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import Slider from './Slider';
import theme from '../../theme';
import { awsUrl } from '../../helpers/config';
import { useOneContext } from '../../helpers/contexts';

const ProductSlider: React.FC<{
	translations: any;
	language: 'en' | 'ar';
}> = ({ translations, language }) => {
	const mobile = useMediaQuery(theme.breakpoints.only('xs'));

	const { isOnePage } = useOneContext();

	const standardSlides = [
		<Box
			className={`slider-item ${isOnePage ? 'one' : ''}`}
			sx={{
				background: '#00D1D2',
			}}
		>
			<img
				className={'slider-item-full-image'}
				src={`${awsUrl}/${mobile ? 'mobile-group-1856.png' : 'desktop-group-1886.png'}`}
				alt="iqos products"
			/>
		</Box>,
		<Box
			className={`slider-item ${isOnePage ? 'one' : ''}`}
			sx={{
				background: '#FFD040',
			}}
		>
			<Stack
				className={'slider-item-content'}
				sx={{
					flexDirection: mobile ? 'column' : 'row',
				}}
			>
				<Box className={'left'}>
					<img
						className={'slider-item-half-image iqos-circle'}
						src={`${awsUrl}/desktop-rectangle1341.png`}
						alt="iqos products"
					/>
				</Box>
				<Box className={'right'}>
					<Typography variant={'h2'} color={'white'}>
						{translations.slider.technology}
					</Typography>
					<Typography variant={'h4'} className={'you-can-trust'} color={'primary'}>
						{translations.slider.youCanTrust}
					</Typography>
					<Typography className={'right-text'} variant={'body1'}>
						{translations.slider.heartBladeless}
					</Typography>
				</Box>
			</Stack>
		</Box>,
		<Box
			className={'slider-item'}
			sx={{
				background: '#287BAF',
				flexDirection: mobile ? 'column' : 'row',
			}}
		>
			<Stack
				className={'slider-item-content other'}
				sx={{
					flexDirection: mobile ? 'column-reverse' : 'row',
				}}
			>
				<Box className={'right'}>
					<Typography variant={'h4'} className={'next-level'}>
						{translations.slider.nextLevel}
					</Typography>
					<Typography variant={'h2'} color={'white'}>
						{translations.slider.simplicity}
					</Typography>
					<Typography className={'right-text beautifully-designed'} variant={'body1'}>
						{translations.slider.ilumaBeautiful}
					</Typography>
					<Button
						sx={{
							mt: 2,
							color: 'white',
							borderColor: 'white',
						}}
						href={'#storeLocator'}
						className={
							'slider-item-content-button gtm-landing-page-dubai-iluma-tag-learn-more-button gtmTrackingCTA'
						}
						variant={'outlined'}
					>
						{translations.slider.learnMore}
					</Button>
				</Box>
				<Box className={'left'}>
					<img
						className={'slider-item-half-image'}
						src={`${awsUrl}/desktop-rectangle-1337.png`}
						alt="iqos products"
					/>
				</Box>
			</Stack>
		</Box>,
	];

	const dubaiOneSlides = [
		<Box
			className={'slider-item one'}
			sx={{
				background: '#00D1D2',
			}}
		>
			<img
				className={'slider-item-full-image'}
				src={`${awsUrl}/	
				egypt-iluma-one-1-${mobile ? 'mobile' : 'desktop'}${mobile ? '.jpg' : '.png'}`}
				alt="iqos products"
			/>
		</Box>,
		<Box
			className={'slider-item one'}
			sx={{
				background: '#00D1D2',
			}}
		>
			<img
				className={'slider-item-full-image'}
				src={`${awsUrl}/	
				egypt-iluma-one-2-${language === 'ar' ? 'arabic-' : ''}${mobile ? 'mobile' : 'desktop'}${
					mobile ? '.jpg' : '.png'
				}`}
				alt="iqos products"
			/>
		</Box>,
	];
	return (
		<Styles className={isOnePage ? 'one' : ''}>
			<Slider>{isOnePage ? dubaiOneSlides : standardSlides}</Slider>
		</Styles>
	);
};

const Styles = styled.div`
	min-height: 100vh;

	&.one {
		min-height: unset;
	}

	direction: ltr;

	* {
		direction: ltr;
	}

	.slider {
		${({ theme }) => theme.breakpoints.up('md')} {
			min-width: 500px;
		}
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		h2 {
			font-size: 3rem;
		}
	}

	.slider-item {
		width: 100vw;
		min-height: 100vh;
		&.one {
			min-height: unset;
		}
		height: 100%;
		display: grid;
		place-items: center;
		flex: 1;
		flex-direction: row;

		${({ theme }) => theme.breakpoints.down('md')} {
			max-width: 100vw;
		}

		.left,
		.right {
			text-align: left;

			${({ theme }) => theme.breakpoints.down('md')} {
				text-align: center;
			}
		}

		.next-level,
		.you-can-trust {
			color: #34303d;
			text-align: center;
		}

		.right-text {
			max-width: 550px;
			text-align: left;
			color: black;
			margin-top: ${({ theme }) => theme.spacing(2)};
			font-size: 1.2rem;

			&.beautifully-designed {
				color: white;
			}

			${({ theme }) => theme.breakpoints.down('md')} {
				max-width: 90%;
				margin: auto;
				margin-top: ${({ theme }) => theme.spacing(4)};
			}

			@media all and (max-width: 380px) {
				font-size: 0.9rem !important;
			}
		}

		&-content {
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;

			@media all and (max-width: 380px) {
				justify-content: flex-start;
			}

			${({ theme }) => theme.breakpoints.down('md')} {
				padding: ${({ theme }) => theme.spacing(2)};

				&-button {
					font-size: 1rem;
				}
			}

			&.other {
				justify-content: space-around;
				@media all and (max-width: 380px) {
					max-height: 600px;
				}
			}
		}

		&-full-image {
			width: 80%;
			height: 80%;
			object-fit: contain;

			&.one {
				width: 100%;
				height: 100%;
			}
		}

		&.one {
			position: relative;
			.slider-item-full-image {
				width: 100%;
				height: 100%;
				min-height: 100%;
				object-fit: contain;
				position: relative;
				left: 0;
				top: 0;
			}
		}

		&-half-image {
			width: 100%;
			max-width: 550px;

			${({ theme }) => theme.breakpoints.up('sm')} {
				&.iqos-circle {
					max-width: 700px;
				}
			}
		}
	}
`;

export default ProductSlider;
