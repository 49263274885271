import React from 'react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

const Header: React.FC<{
	headerImage: any;
}> = ({ headerImage }) => {
	return (
		<Styles>
			<Stack className="header-stack">
				<img src={headerImage} alt="" />
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.header-stack {
		width: 100vw;
		img {
			width: 100%;
			height: 100%;
		}
	}
`;

export default Header;
