import React, { useRef } from "react";
import styled from "@emotion/styled";
import { Box, Container, Drawer, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const HealthWarning: React.FC<{ translations: any }> = ({ translations }) => {
  const [showTerms, setShowTerms] = React.useState<boolean>(false);
  const containerRef = useRef(null);
  return (
    <Styles ref={containerRef}>
      <Container className="health-warning">
        {translations?.healthWarning?.map((item: any) => {
          return (
            <Typography className={"health-warning-para"}>{item}</Typography>
          );
        })}
      </Container>
    </Styles>
  );
};

const Styles = styled.div`
  .terms-popup {
    background-color: white;
    color: black;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(4)};

    &-content {
      position: relative;

      > p {
        margin-bottom: 0.25rem;
      }

      .title {
        text-align: center;
        text-decoration: underline;
        padding-bottom: 1rem;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        color: black;
        cursor: pointer;
      }
    }
  }

  .health-warning {
    border-top: 1px solid #34303d;
    border-bottom: 1px solid #34303d;
    z-index: 1000;
    width: 100vw;
    max-width: unset;
    height: min-content;
    background-color: white;
    text-align: center;
    padding: 20px;

    &-para {
      padding: 0 1rem;
      direction: ltr;
      font-size: 90%;
    }
  }

  .footer-lower {
    background-color: #34303d;
    color: white;
    padding: 2rem;
    margin: 0;
    margin-top: 1rem;

    .terms {
      font-size: 1rem;
      cursor: pointer;
      text-decoration: underline;
    }

    > * {
      padding: 0.25rem 0;
      font-size: 1.25rem;
      font-weight: lighter;
      max-width: 1000px;
      margin: auto;
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      .terms,
      > * {
        font-size: 0.8rem !important;
      }
    }
  }

  .footer {
    background: white;
    z-index: 99;
    min-height: 70px;
    display: flex;
    justify-content: center;
    padding: 10px 20px;

    .footer-logo {
      max-height: 20px;
    }

    .footer-copyright {
      font-size: 10px;
    }

    .footer-list-link {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      margin: 16px 0;
      letter-spacing: 0;
      text-align: right;
      padding: 0;
    }

    .footer-mobile-icon {
      color: #111;

      svg {
        fill: #111;
      }
    }
  }
`;

export default HealthWarning;
