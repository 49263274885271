import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import AgeVerification from '../../components/AgeVerification';
import DubaiDIWPage from '../../components/DubaiDI&WPage';
import DubaiDIWPageOne from '../../components/DubaiDI&WPageOne';

import en from '../dubai/lang/en.json';
import ar from '../dubai/lang/ar.json';

const DubaiIlumaEntry: React.FC<{lang: 'en' | 'ar'}> = (props) => {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    document.title = 'IQOS Travel';
    const ageVerification = localStorage.getItem('ageVerification');

    if (ageVerification === 'true') {
      setVerified(true);
    }
  }, [verified]);
  
  return (
    <Styles>
      {
        props.lang === 'en' ? 
          <DubaiDIWPage language={props.lang} translations={en} /> : 
          <DubaiDIWPageOne language={props.lang} translations={ar} />
      }
      <AgeVerification
          location=""
          language="en"
          verified={verified}
          hasSubmitted={verified}
          url={'/'}
        />
    </Styles>
  );
};

const Styles = styled.div``;

export default DubaiIlumaEntry;