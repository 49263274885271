import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Box, Container, Drawer, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const Component: React.FC<{ translations: any; language: any }> = ({ translations, language }) => {
	const [showTerms, setShowTerms] = React.useState<boolean>(false);

	const containerRef = useRef(null);
	return (
		<Styles ref={containerRef}>
			<Container className="health-warning">
				<Typography className={'health-warning-para'}>
					This product is not risk free and provides nicotine, which is addictive.
				</Typography>
				<Typography className={'health-warning-para'}>
					{language === 'en'
						? 'Only for use by adults who would otherwise continue to smoke or use other nicotine products'
						: 'Produk ini tidak bebas risiko dan mengandung nikotin yang menyebabkan ketergantungan. Hanya diperuntukkan bagi pengguna dewasa (18+).a'}
				</Typography>
			</Container>

			<Drawer
				anchor="bottom"
				open={showTerms}
				disablePortal
				onClose={() => setShowTerms(false)}
			>
				<Stack className="terms-popup">
					<Box className="terms-popup-content">
						<Close className="close" onClick={() => setShowTerms(false)} />
						<Typography className="title" variant="h6">
							{translations?.terms?.title}
						</Typography>
						<Typography>{translations?.terms?.legalAge}</Typography>
						<Typography>{translations?.terms?.offer}</Typography>
						<Typography>{translations?.terms?.basketSize}</Typography>
						<Typography>{translations?.terms?.stocksLast}</Typography>
						<Typography>{translations?.terms?.onePerCustomer}</Typography>
					</Box>
				</Stack>
			</Drawer>
		</Styles>
	);
};

const Styles = styled.div`
	.terms-popup {
		background-color: white;
		color: black;
		justify-content: center;
		align-items: center;
		padding: ${({ theme }) => theme.spacing(4)};

		&-content {
			position: relative;

			> p {
				margin-bottom: 0.25rem;
			}

			.title {
				text-align: center;
				text-decoration: underline;
				padding-bottom: 1rem;
			}

			.close {
				position: absolute;
				top: 0;
				right: 0;
				color: black;
				cursor: pointer;
			}
		}
	}

	.health-warning {
		z-index: 1000;
		width: 100vw;
		max-width: unset;
		height: min-content;
		background-color: white;
		text-align: center;
		padding: 0;
		margin-top: 10px;
		margin-bottom: 10px;

		&-para {
			padding: 0 1rem;
			direction: ltr;
			font-size: 90%;
		}
	}

	.footer-lower {
		background-color: #34303d;
		color: white;
		padding: 2rem;
		margin: 0;
		margin-top: 1rem;

		.terms {
			font-size: 1rem;
			cursor: pointer;
			text-decoration: underline;
		}

		> * {
			padding: 0.25rem 0;
			font-size: 1.25rem;
			font-weight: lighter;
			max-width: 1000px;
			margin: auto;
		}

		${({ theme }) => theme.breakpoints.down('md')} {
			.terms,
			> * {
				font-size: 0.8rem !important;
			}
		}
	}

	.footer {
		background: white;
		z-index: 99;
		min-height: 70px;
		display: flex;
		justify-content: center;
		padding: 10px 20px;

		.footer-logo {
			max-height: 20px;
		}

		.footer-copyright {
			font-size: 10px;
		}

		.footer-list-link {
			text-transform: uppercase;
			font-weight: bold;
			font-size: 11px;
			margin: 16px 0;
			letter-spacing: 0;
			text-align: right;
			padding: 0;
		}

		.footer-mobile-icon {
			color: #111;

			svg {
				fill: #111;
			}
		}
	}
`;

export default Component;
