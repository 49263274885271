import { Container, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Header from '../../components/DynamicPromoPage/Header';
import Footer from '../../components/DynamicPromoPage/Footer';
import PreOrderBar, { Operator } from '../../components/DynamicPromoPage/PreOrder';
import Navigation from '../../components/Navigation';
import Offer from './Offer/Offer';
const preOrderOperators: Operator[] = [
	{
		operator: '中部 – Centrair Duty Free',
		link: 'https://duty-free.centrair.jp/product/list.aspx?keyword=iqos&refine=1',
	},
	{
		operator: '関西 - KIX DUTY FREE',
		link: 'https://www.kixdutyfree.jp/ComMainVisual.aspx?file=IQOS&src=cat-IQOS&utm_source=iqos&utm_medium=lp_ad&utm_campaign=jp_from202206',
	},
	{
		operator: '成田 – ANA DUTY FREE SHOP',
		link: 'https://www.anadf.com/promo/iqos/?utm_source=pm_paidmedia2206&utm_medium=ec2206_jp',
	},
	{
		operator: '成田 - JAL DUTY FREE',
		link: 'https://www.jaldfs.co.jp/shop/c/c500010/',
	},
	{
		operator: '羽田 – ANA DUTY FREE SHOP',
		link: 'https://www.anadf.com/promo/iqos/?utm_source=pm_paidmedia2206&utm_medium=ec2206_jp',
	},
	{
		operator: '羽田 – JAPAN DUTY FREE',
		link: 'https://duty-free-japan.jp/narita/en/goodsList.aspx?brdcd=695&catcd=042&searchfilter=0000000&count=10&order=0',
	},
	{
		operator: '羽田 - JAL DUTY FREE',
		link: 'https://www.jaldfs.co.jp/shop/c/c500010/',
	},
];

const googleMapsToUrl = (data: string) => {
	// converts formats like 福岡空港ビルディング（株）+免税店/@33.5856326,130.4415571 to url
	const [name, coords] = data.split('/@');
	const [north, south, east, west] = coords.split(',');
	return `https://iqostravel.com/ja/store-locator?prefill-name=${name.replace(
		/\+/g,
		'%20'
	)}&north=${north}&south=${south}&east=${east}&west=${west}&skipDialogs=true&country=japan&referrer=we`;
};

const newAirports: Operator[] = [
	{
		operator: '中部国際空港(Centrair)',
		link: googleMapsToUrl(
			'Centrair+Duty+Free+酒・タバコ/@34.878903,34.838078,136.829696,136.790856'
		),
	},
	{
		operator: '福岡空港',
		link: googleMapsToUrl(
			'福岡空港ビルディング（株） 免税店/@33.602762,33.570361,130.462622,130.437785'
		),
	},
	{
		operator: '関西国際空港',
		link: googleMapsToUrl('KIX DUTY FREE 本館北店/@34.438,34.436,135.280,135.200'),
	},
	{
		operator: '成田国際空港',
		link: googleMapsToUrl(
			'ANA DUTY FREE SHOP 第1ターミナル南ウイング/@35.783,35.741,140.402,140.361'
		),
	},
	{
		operator: '羽田空港',
		link: googleMapsToUrl('TIAT DUTY FREE CENTRAL (JATCO)/@35.556,35.544,139.790,139.766'),
	},
];


const DynamicPromoPage: React.FC<{
	language: 'ja';
	translations: any;
	type: string;
	headerImage: any;
	mobile: any;
	pageColor: any;
	leadComponent: any;
	offerImage: any;
	offerColor: any;
	offerTitles: any;
	offerBodyTextDesktop: any;
	offerBodyTextMobile: any;
	footerImage: any;
	footerTitleColor: any;
	footerTitle: any;
	footerBody: any;
	offerButtonLink: any;
}> = ({
	language,
	translations,
	type,
	headerImage,
	mobile,
	pageColor,
	leadComponent,
	offerImage,
	offerColor,
	offerTitles,
	offerBodyTextDesktop,
	offerBodyTextMobile,
	footerImage,
	footerTitleColor,
	footerTitle,
	footerBody,
	offerButtonLink,
}) => {
	const [operators, setOperators] = useState<Operator[]>([]);
	useEffect(() => {
		setOperators([...(type === 'pre-order' ? preOrderOperators : newAirports)]);
	}, [type]);
	return (
		<Styled>
			<Navigation
				countryName="Japan"
				language="ja"
				languages={[
					{
						code: 'ja',
						flag: 'ja',
						url: 'ja',
					},
				]}
				translations={translations}
			/>
			<Stack
				justifyContent="center"
				alignItems="center"
				spacing={4}
				style={{ backgroundColor: pageColor }}
			>
				<Stack justifyContent="center" alignItems="center" className="inner-stack">
					<Header headerImage={headerImage} />
					<PreOrderBar
						market="Japan"
						translations={translations}
						operators={operators}
						type={type}
					/>
				</Stack>
				{mobile ? (
					<>
						<Container maxWidth="lg">
							<Stack
								justifyContent="center"
								alignItems="center"
								spacing={4}
								className="inner-stack"
							>
								{/* Lead component isn't fully dynamic due to specific styling requirements */}
								{leadComponent}
							</Stack>
						</Container>
						{/* <Warning /> */}
						<Container maxWidth="lg">
							<Stack
								justifyContent="center"
								alignItems="center"
								spacing={4}
								className="inner-stack"
							>
								<Offer
									backgroundImage={offerImage}
									backgroundColor={offerColor}
									titleSection={offerTitles}
									bodySection={offerBodyTextMobile}
									buttonLink={offerButtonLink}
								/>
								{/* <InternationalAssistance /> */}
							</Stack>
						</Container>
					</>
				) : (
					<Container maxWidth="lg">
						<Stack
							justifyContent="center"
							alignItems="center"
							spacing={4}
							className="inner-stack"
						>
							{/* Lead component isn't fully dynamic due to specific styling requirements */}
							{leadComponent}
							{/* <Warning message={warningMessage} /> */}
							<Offer
								backgroundImage={offerImage}
								backgroundColor={offerColor}
								titleSection={offerTitles}
								bodySection={offerBodyTextDesktop}
								buttonLink={offerButtonLink}
							/>
							{/* <InternationalAssistance /> */}
						</Stack>
					</Container>
				)}

				<Stack justifyContent="center" alignItems="center" className="inner-stack">
					<Footer
						backgroundImage={footerImage}
						titleColor={footerTitleColor}
						title={footerTitle}
						bodyText={footerBody}
					/>
					<PreOrderBar
						market="Japan"
						translations={translations}
						operators={operators}
						type={type}
					/>
				</Stack>
			</Stack>
		</Styled>
	);
};

export default DynamicPromoPage;

const Styled = styled.div`
	/* Override word break for korean page as korean words will split in the middle when they shouldn't */
	word-break: keep-all;
	.inner-stack {
		width: 100%;
	}
`;
