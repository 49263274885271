import React from 'react';
import styled from '@emotion/styled';
import { Stack, useMediaQuery } from '@mui/material';
import { awsUrl } from '../../../helpers/config';

const Header: React.FC = () => {
	const small = useMediaQuery('(max-width: 700px)');
	return (
		<Styles>
			<Stack className="header-stack">
				<img
					src={
						small
							? `${awsUrl}/we-jp-hero-mobile.jpg`
							: `${awsUrl}/we-jp-hero-desktop.jpg`
					}
					alt=""
				/>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.header-stack {
		width: 100vw;
		img {
			width: 100%;
			height: 100%;
		}
	}
`;

export default Header;
