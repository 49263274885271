import { Container, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import Header from "./Header";
import Footer from "./Footer";
import Lead from "./Lead";
import InternationalAssistance from "./InternationalAssistance";
import Warning from "./Warning";
import PreOrderBar, { Operator } from "./PreOrder";
import Navigation from "../Navigation";
import Offer from "./Offer";

const preOrderOperators: Operator[] = [
  {
    operator: "中部 – Centrair Duty Free",
    link: "https://duty-free.centrair.jp/product/list.aspx?keyword=iqos&refine=1",
  },
  {
    operator: "関西 - KIX DUTY FREE",
    link: "https://www.kixdutyfree.jp/ComMainVisual.aspx?file=IQOS&src=cat-IQOS&utm_source=iqos&utm_medium=lp_ad&utm_campaign=jp_from202206",
  },
  {
    operator: "成田 – ANA DUTY FREE SHOP",
    link: "https://www.anadf.com/promo/iqos/?utm_source=pm_paidmedia2206&utm_medium=ec2206_jp",
  },
  {
    operator: "成田 - JAL DUTY FREE",
    link: "https://www.jaldfs.co.jp/shop/c/c500010/",
  },
  {
    operator: "羽田 – ANA DUTY FREE SHOP",
    link: "https://www.anadf.com/promo/iqos/?utm_source=pm_paidmedia2206&utm_medium=ec2206_jp",
  },
  {
    operator: "羽田 – JAPAN DUTY FREE",
    link: "https://duty-free-japan.jp/narita/en/goodsList.aspx?brdcd=695&catcd=042&searchfilter=0000000&count=10&order=0",
  },
  {
    operator: "羽田 - JAL DUTY FREE",
    link: "https://www.jaldfs.co.jp/shop/c/c500010/",
  },
];

const googleMapsToUrl = (data: string) => {
  // converts formats like 福岡空港ビルディング（株）+免税店/@33.5856326,130.4415571 to url
  const [name, coords] = data.split("/@");
  const [north, south, east, west] = coords.split(",");
  return `https://iqostravel.com/ja/store-locator?prefill-name=${name.replace(
    /\+/g,
    "%20"
  )}&north=${north}&south=${south}&east=${east}&west=${west}&skipDialogs=true&country=japan&referrer=we`;
};

const newAirports: Operator[] = [
  {
    operator: "中部国際空港(Centrair)",
    link: googleMapsToUrl(
      "Centrair+Duty+Free+酒・タバコ/@34.878903,34.838078,136.829696,136.790856"
    ),
  },
  {
    operator: "福岡空港",
    link: googleMapsToUrl(
      "福岡空港ビルディング（株） 免税店/@33.602762,33.570361,130.462622,130.437785"
    ),
  },
  {
    operator: "関西国際空港",
    link: googleMapsToUrl(
      "KIX DUTY FREE 本館北店/@34.438,34.436,135.280,135.200"
    ),
  },
  {
    operator: "成田国際空港",
    link: googleMapsToUrl(
      "ANA DUTY FREE SHOP 第1ターミナル南ウイング/@35.783,35.741,140.402,140.361"
    ),
  },
  {
    operator: "羽田空港",
    link: googleMapsToUrl(
      "TIAT DUTY FREE CENTRAL (JATCO)/@35.556,35.544,139.790,139.766"
    ),
  },
];

const WePage: React.FC<{
  language: "ja";
  translations: any;
  type: string;
}> = ({ language, translations, type }) => {
  const small = useMediaQuery("(max-width: 700px)");
  const operators = type === "pre-order" ? preOrderOperators : newAirports;
  return (
    <Styled>
      <Navigation
        countryName="Japan"
        language="ja"
        languages={[
          {
            code: "ja",
            flag: "ja",
            url: "ja",
          },
        ]}
        translations={translations}
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={4}
        style={{ backgroundColor: "#26242f" }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          className="inner-stack"
        >
          <Header />
          <PreOrderBar
            market="Japan"
            translations={translations}
            operators={operators}
            type={type}
          />
        </Stack>
        {small ? (
          <>
            <Container maxWidth="lg">
              <Stack
                justifyContent="center"
                alignItems="center"
                spacing={4}
                className="inner-stack"
              >
                <Lead
                  translations={{ lead: { title: "test", text: "test" } }}
                />
              </Stack>
            </Container>
            <Warning />
            <Container maxWidth="lg">
              <Stack
                justifyContent="center"
                alignItems="center"
                spacing={4}
                className="inner-stack"
              >
                <Offer />
                <InternationalAssistance />
              </Stack>
            </Container>
          </>
        ) : (
          <Container maxWidth="lg">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={4}
              className="inner-stack"
            >
              <Lead translations={{ lead: { title: "test", text: "test" } }} />
              <Warning />
              <Offer />
              <InternationalAssistance />
            </Stack>
          </Container>
        )}

        <Stack
          justifyContent="center"
          alignItems="center"
          className="inner-stack"
        >
          <Footer />
          <PreOrderBar
            market="Japan"
            translations={translations}
            operators={operators}
            type={type}
          />
        </Stack>
      </Stack>
    </Styled>
  );
};

export default WePage;

const Styled = styled.div`
  /* Override word break for korean page as korean words will split in the middle when they shouldn't */
  word-break: keep-all;
  .inner-stack {
    width: 100%;
  }
`;
