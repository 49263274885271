import { createContext, useContext } from 'react';

export const ClientStyleContext = createContext<{
	reset: () => void;
}>({
	reset: () => {},
});

export interface ServerStyleContextData {
	key: string;
	ids: Array<string>;
	css: string;
}

export const ServerStyleContext = createContext<ServerStyleContextData[]>([]);

export const MessageContext = createContext<{
	message: any | null;
}>({
	message: null,
});

export const OneContext = createContext<{
	isOnePage: boolean | null;
}>({
	isOnePage: null,
});

export const useOneContext = () => useContext(OneContext);

export const CountryContext = createContext<{
	country: string | null;
	setCountryOnce: (country: string) => void;
}>({
	country: null,
	setCountryOnce: (country) => null,
});

export const useCountry = () => useContext(CountryContext);
