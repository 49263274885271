import { Container, Stack, useMediaQuery, Box } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import Header from "./Header";
import PreOrderBar, { Operator } from "./PreOrder";
import Navigation from "../Navigation";
import { GoogleMapFullWidth } from "../FullMap";
import HealthWarning from "../common/HealthWarning";
import TextFooter from "../common/TextFooter";

const KrStardriftPage: React.FC<{
  language: string;
  translations: any;
  type: string;
}> = ({ language, translations, type }) => {
  const small = useMediaQuery("(max-width: 700px)");
  const [currentMarkers, setCurrentMarkers] = useState([]);
  const [currentOperator, setCurrentOperator] = useState(null);

  const handleUpdateMarkers = (newMarkers: React.SetStateAction<never[]>) => {
    setCurrentMarkers(newMarkers);
  };

  const handleUpdateOperator = (operator: React.SetStateAction<null>) => {
    setCurrentOperator(operator);
  };
  return (
    <Styled>
      <Navigation
        countryName="ID"
        language={language}
        languages={[
          {
            code: "kr",
            flag: "kr",
            url: "kr",
            name: "Korean",
          },
          {
            code: "en",
            flag: "gb",
            url: "en",
            name: "English",
          },
        ]}
        // onSidebarOpen={() => setSidebarOpen(true)}
        translations={translations}
      />
      <Stack justifyContent="center" alignItems="center" spacing={4}>
        <Stack
          justifyContent="center"
          alignItems="center"
          className="inner-stack"
        >
          <Header langCode={language} />
          <PreOrderBar
            market="Japan"
            translations={translations}
            firstOperators={translations.preOrderBar.defaultOptions}
            secondOperators={
              currentOperator === "Airport"
                ? translations.preOrderBar.airportOptions
                : translations.preOrderBar.downtownOptions || []
            }
            type={type}
            updateMarkers={handleUpdateMarkers}
            updateOperator={handleUpdateOperator}
          />
          <GoogleMapFullWidth
            defaultFocusCoords={{
              lat: 37.44945398706875,
              lng: 126.45136450818242,
            }}
            markers={currentMarkers}
          />
        </Stack>
      </Stack>
      <HealthWarning translations={translations} />
      <TextFooter translations={translations} />
    </Styled>
  );
};

export default KrStardriftPage;

const Styled = styled.div`
  /* Override word break for korean page as korean words will split in the middle when they shouldn't */
  word-break: keep-all;
  .inner-stack {
    width: 100%;
  }
`;
