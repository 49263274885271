import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { useOneContext } from "../../helpers/contexts";
import { awsUrl, awsSubtitlesUrl } from "../../helpers/config";

const LandingVideo: React.FC<{
  translations: any;
  language: "en" | "ar";
  awsFileName: string;
  disableFindClosest?: any;
}> = ({ translations, language, awsFileName, disableFindClosest }) => {
  const { isOnePage } = useOneContext();

  return (
    <Styles>
      <Box className={"landing"}>
        <Box className={"video-wrapper"}>
          <video
            crossOrigin="anonymous"
            controls
            height="100%"
            width="100%"
            muted
            autoPlay
            loop
            className="landing-video"
          >
            {language !== "en" && (
              <track
                src={`${awsSubtitlesUrl}/${awsFileName}-${language}.vtt`}
                kind="subtitles"
                srcLang={language}
                label={language}
                default
              />
            )}
            <source src={`${awsUrl}/${awsFileName}.webm`} type="video/webm" />
            <source src={`${awsUrl}/${awsFileName}.mp4`} type="video/mp4" />
          </video>
        </Box>
        <Box
          flex={3}
          className={`landing-text ${isOnePage ? "one-landing" : ""}`}
        >
          <Stack>
            <Typography
              className={"landing-text-title"}
              variant={"h3"}
              color={"white"}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: translations.landing.nextLevelForward,
                }}
              />
            </Typography>

            <Typography className={"landing-text-title blue"} variant={"h3"}>
              {translations.landing.noBlade}
            </Typography>
            <Typography
              style={{ paddingBlock: 0 }}
              className={"landing-text-title blue twenty-uses"}
              variant={"h3"}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: translations?.landing?.twentyUses,
                }}
              ></span>
            </Typography>
            {translations?.landing?.noCleaning.length >= 1 && (
              <Typography
                style={{ paddingTop: 0 }}
                className={"landing-text-title blue"}
                variant={"h3"}
              >
                {translations.landing.noCleaning}
              </Typography>
            )}
          </Stack>

          <Typography sx={{ mt: 2, mb: 1 }} variant={"body1"}>
            {translations.landing.bladeExplanation}
          </Typography>

          <Button
            className="landing-button gtm-landing-page-dubai-iluma-tag-iluma-pre-order-now-button gtmTrackingCTA"
            sx={{ my: 2 }}
            component={Link}
            href={`https://iqostravel.com/${language}/pre-order`}
            variant={"contained"}
          >
            {translations.landing.preOrderNow}
          </Button>
          {!disableFindClosest && (
            <Button
              className="landing-button gtm-landing-page-dubai-iluma-tag-find-your-closest-store-button gtmTrackingCTA"
              variant={"contained"}
              component={Link}
              href={"#storeLocator"}
            >
              {translations.landing.findClosest}
            </Button>
          )}
        </Box>
      </Box>
    </Styles>
  );
};

const Styles = styled.div`
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  min-height: 100vh;
  //border: solid green;
  background-color: #00d1d2;

  .landing {
    .twenty-uses {
      font-weight: normal;
      font-size: 1.8rem;
      max-width: 375px;
      padding-top: ${({ theme }) => theme.spacing(2)};
    }

    //max-width: 1200px;

    ${({ theme }) => theme.breakpoints.up("sm")} {
      //margin: ${({ theme }) => theme.spacing(4)};
    }

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 100vh;

    &-video {
      max-width: 100%;
      aspect-ratio: 16 / 9;
    }

    &-text {
      display: flex;
      flex-direction: column;
      margin: ${({ theme }) => theme.spacing(0, 3)};

      &.one-landing {
        ${({ theme }) => theme.breakpoints.down("xl")} {
          max-width: 50%;
        }

        ${({ theme }) => theme.breakpoints.down("md")} {
          max-width: unset;
        }
      }

      &-title {
        font-size: 2rem;

        ${({ theme }) => theme.breakpoints.down("md")} {
          font-size: 1.8rem;
        }

        ${({ theme }) => theme.breakpoints.between("xs", "sm")} {
          font-size: 1.5rem !important;
        }
      }

      .blue {
        color: #34303d;
        padding: ${({ theme }) => theme.spacing(2, 0)};
      }
    }

    .video-wrapper {
      max-width: 1200px;
      flex: 1;

      ${({ theme }) => theme.breakpoints.up("md")} {
        margin: ${({ theme }) => theme.spacing(6)};
        flex: 5;
      }
    }

    &-button {
      width: 100%;
      max-width: 280px;
      padding: 10px 24px;
      //align-self: center;
      background: #34303d;
      font-size: 1rem;

      ${({ theme }) => theme.breakpoints.down("md")} {
        max-width: 80vw;
        padding: 1rem 0.75rem;
      }
    }

    .health-warning {
      background-color: white;
      min-height: 4rem;
      transform: translateY(-10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      border: solid 1px dimgrey;
      justify-content: center;

      ${({ theme }) => theme.breakpoints.down("md")} {
        justify-content: flex-start;
      }

      align-items: center;
      position: relative;

      &-fullscreen {
        position: absolute;
        right: 1rem;
        cursor: pointer;
      }

      &-text {
        font-size: 0.75rem;
        text-align: center;
        max-width: 600px;

        &.arabic {
          direction: rtl;
        }

        ${({ theme }) => theme.breakpoints.down("md")} {
          max-width: 85vw;
          margin: auto;
          padding-left: 1rem;
          padding-right: 1rem;
        }

        ${({ theme }) => theme.breakpoints.only("xs")} {
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  }
`;

export default LandingVideo;
