import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import AgeVerification from '../../components/AgeVerification';
import WePage from '../../components/WePage';

import data from '../bright/lang/jp.json';


const WeJPEntry: React.FC = () => {
  const [verified, setVerified] = useState(false);
	const [type, setType] = useState<string>('');

  useEffect(() => {
    document.title = 'IQOSイルマ WE2023';
    const ageVerification = localStorage.getItem('ageVerification');

    if (ageVerification === 'true') {
      setVerified(true);
    }
  }, [verified]);

	useEffect(() => {
		const url = new URL(window.location.href);
		let _type = url.searchParams.get('type') as string;
		if (!_type) {
			url.searchParams.append('type', 'pre-order');
			window.history.pushState({}, '', url.href);
			_type = 'pre-order';
		}

		setType(_type);
  }, []);

	const [html, setHTML] = useState('');

	useEffect(() => {
		const script1 = document.createElement('script');
		script1.src = 'https://s.yimg.jp/images/listing/tool/cv/ytag.js';
		script1.async = true;
		document.body.appendChild(script1);

		const script2 = document.createElement('script');
		script2.src = 'https://js.fout.jp/conversion.js';
		script2.async = true;
		document.body.appendChild(script2);

		setHTML(`
      window.yjDataLayer = window.yjDataLayer || [];
      function ytag() { yjDataLayer.push(arguments); }
      ytag({"type":"ycl_cookie"});
      
      window._fout_queue = window._fout_queue || {}; if (window._fout_queue.conv === void 0) window._fout_queue.conv = {};
	  if (_fout_queue.conv.queue === void 0) _fout_queue.conv.queue = [];
    `);
	}, []);

	return (
		<Styles>
				<WePage language="ja" translations={data} type={type} />
				<AgeVerification
					location="oasis"
					language="ja"
					verified={verified}
					hasSubmitted={verified}
					url={'/'}
				/>
			<script
				async
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
		</Styles>
	);
};

const Styles = styled.div`
	padding: 0;
`;

export default WeJPEntry;
