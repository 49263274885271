import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import useEmblaCarousel from 'embla-carousel-react';
import { useOneContext } from '../../helpers/contexts';

const Slider: React.FC<{
	children: React.ReactNode;
}> = ({ children: _children }) => {
	const [sliderRef, sliderApi] = useEmblaCarousel({
		align: 'center',
		loop: true,
	});
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const handleSelect = () => {
		if (!sliderApi) return;
		setActiveIndex(sliderApi.selectedScrollSnap());
	};

	const scrollTo = useCallback(
		(index: number) => sliderApi && sliderApi.scrollTo(index),
		[sliderApi]
	);

	useEffect(() => {
		if (!sliderApi) return;
		//needed but don't know why
		//https://github.com/davidjerleke/embla-carousel/issues/195#issuecomment-850955215
		sliderApi.reInit();

		setActiveIndex(sliderApi?.selectedScrollSnap());
		sliderApi.on('select', handleSelect);

		return () => {
			sliderApi.off('select', handleSelect);
		};
	}, [sliderApi]);

	const children = useMemo(
		() => _children,
		// React.Children.count(_children) < 2
		// 	? [...React.Children.toArray(_children), ...React.Children.toArray(_children)]
		// 	: React.Children.toArray(_children),
		[_children]
	);

	const { isOnePage } = useOneContext();

	return (
		<Styles>
			<div ref={sliderRef} className="slider embla">
				<div className={`slider-container embla__container ${isOnePage ? 'one' : ''}`}>
					{React.Children.map(children, (child, i) => (
						<div
							className={`slider-item embla__slide ${
								activeIndex === i ? 'slider-item-active' : ''
							} ${isOnePage ? 'one' : ''}`}
							key={i}
						>
							{child}
						</div>
					))}
				</div>

				<div
					className="embla__dots"
					style={{
						margin: 'auto',
						width: '100%',
						transform: isOnePage ? 'unset' : 'translateY(-64px)',
						height: 0,
						position: 'relative',
						bottom: isOnePage ? '-15%' : 'unset',
						zIndex: 100,
					}}
				>
					{React.Children.map(children, (_, index) => (
						<button
							className={`embla__dot ${index === activeIndex ? 'is-selected' : ''}`}
							type="button"
							onClick={() => scrollTo(index)}
							style={{
								background: '#34303D',
								width: index === activeIndex ? '35px' : '10px',
								height: '10px',
								margin: '0.5em',
								border: 'none',
								borderRadius: '5px',
							}}
						/>
					))}
				</div>
			</div>
		</Styles>
	);
};

export default Slider;

const Styles = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	.slider {

        .embla__dots{
	        width: 100%;
	        display: flex;
            justify-content: center;
            align-items: center;
	        margin-top: 2rem;
	        
	        button{
                transition: 0.2s ease;
            }
        }
		
		overflow: hidden;
		.slider-container {
			
			&.one {
                height: 80%;
            }
			display: flex;
		}
		.slider-item {
			opacity: 1;
			position: relative;
			flex: 0 0 100%;
			//padding-right: ${({ theme }) => theme.spacing(4)};
			transition: ${({ theme }) => theme.transitions.create(['opacity'])};
			${({ theme }) => theme.breakpoints.up('md')} {
				flex: 0 0 100%;
			}
			${({ theme }) => theme.breakpoints.up('lg')} {
				flex: 0 0 50%;
			}
		}
		.slider-item-active {
			opacity: 1;
		}
		// .slider-next {
		// 	position: absolute;
		// 	left: 93.75%;
		// 	top: 50%;
		// 	transform: translateY(-50%) translateX(-50%);
		// 	${({ theme }) => theme.breakpoints.up('md')} {
		// 		left: 87.5%;
		// 	}
		// 	${({ theme }) => theme.breakpoints.up('lg')} {
		// 		left: 75%;
		// 	}
		}
	}
`;
