import React from 'react';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';

const Footer: React.FC<{ backgroundImage: any; titleColor: any; title: any; bodyText: any }> = ({
	backgroundImage,
	titleColor,
	title,
	bodyText,
}) => {
	return (
		<Styles>
			<Stack className={'wrapper'}>
				<img src={backgroundImage} alt="" className="header-image" />
				<Stack className="text" spacing={2}>
					<Typography
						style={{ color: titleColor, fontFamily: 'IqosSans' }}
						variant="h4"
						sx={{ fontSize: 'min(4.8vw, 3rem)' }}
					>
						{title}
					</Typography>
					<Typography
						variant="body2"
						style={{ fontFamily: 'IqosSans' }}
						sx={{ fontSize: 'min(2.5vw, 2rem)' }}
					>
						{bodyText}
					</Typography>
				</Stack>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	position: relative;

	.wrapper {
		width: 100vw;
		img {
			height: 100%;
			min-height: 200px;
		}
	}

	.text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		text-align: center;
		text-shadow: 2px 2px #000;

		color: white;
	}
`;

export default Footer;
